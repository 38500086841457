/* eslint-disable */
import {
  Box,
  Breadcrumbs,
  Button,
  CircularProgress,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography
} from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate, useSearchParams } from 'react-router-dom';
import LogTable from '../components/LogTable';
import { useGet, useHttpDelete, usePost } from '../request';

const LogPage = function () {
  const post = usePost();
  const get = useGet();
  const del = useHttpDelete();
  const [isShowLoader, setIsShowLoader] = useState(false);
  const [filterMethod, setFilterMethod] = useState('networks');
  const navigate = useNavigate();
  const [URLSearchParams, SetURLSearchParams] = useSearchParams();

  let [logs, setLogs] = useState([]);

  const handleFilterMethodChange = (event) => {
    setFilterMethod(event.target.value);
    SetURLSearchParams(`filter=${event.target.value}`);
  };

  const loadLog = () => {
    setIsShowLoader(true);
    get(`admin/logs?category=${URLSearchParams.get('filter') || filterMethod}`)
      .then((response) => {
        setIsShowLoader(false);
        if (response.result === 'success') {
          setLogs(response.logs);
        }
      })
      .catch((e) => console.log(e));
  };

  const clearLogs = () => {
    del(`admin/logs?category=${filterMethod}`)
      .then((response) => {
        if (response.result === 'success') {
          setLogs([]);
        }
      })
      .catch((e) => console.log(e));
  };

  useEffect(() => {
    if (URLSearchParams.has('filter')) {
      const filter = URLSearchParams.get('filter');
      setFilterMethod(filter);
    }

    loadLog();
  }, []);

  return (
    <>
      <Helmet>
        <title>Log | Material Kit</title>
      </Helmet>
      <Box sx={{ backgroundColor: 'background.default', minHeight: '100%' }}>
        <Container maxWidth={false}>
          <Box sx={{ py: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', pb: 1 }}>
              <Box style={{ marginTop: 3, marginRight: 10 }}>
                <Button
                  startIcon={<ChevronLeft />}
                  onClick={() => navigate(-1)}
                >
                  Back
                </Button>
              </Box>
              <Typography color="textPrimary" variant="h4" component="h1">
                Log
              </Typography>
            </Box>
            <Breadcrumbs aria-label="breadcrumb" style={{ fontSize: 12 }}>
              <p>Log</p>
            </Breadcrumbs>
          </Box>
          <Box>
            <Paper sx={{ p: 4 }}>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} sm={6} md={4}>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel id="filter-method-label">
                      Filter method
                    </InputLabel>
                    <Select
                      labelId="filter-method-label"
                      id="filter-method"
                      value={filterMethod}
                      onChange={handleFilterMethodChange}
                      label="Filter method"
                    >
                      <MenuItem value="networks">Default</MenuItem>
                      <MenuItem value="newbenefits">NewBenefits</MenuItem>
                      <MenuItem value="sql">SQL</MenuItem>
                      <MenuItem value="app">Application</MenuItem>
                      <MenuItem value="recuro">Recuro</MenuItem>
                      <MenuItem value="none">No filters</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Button
                    sx={{ width: 101, height: 55 }}
                    color="primary"
                    variant="contained"
                    onClick={loadLog}
                  >
                    Filter
                  </Button>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={4}
                  sx={{ display: 'flex', justifyContent: 'flex-end' }}
                >
                  {filterMethod !== 'none' && (
                    <Button
                      sx={{ width: 121, height: 55 }}
                      color="error"
                      variant="contained"
                      onClick={clearLogs}
                    >
                      Clear Logs
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Paper>
          </Box>
          {isShowLoader ? (
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: 5
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <LogTable logs={logs} />
          )}
        </Container>
      </Box>
    </>
  );
};

export default LogPage;

