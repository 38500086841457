import { Helmet } from 'react-helmet';
import {
    Box,
    Container
} from '@material-ui/core';
import { useParams } from 'react-router-dom';
import ServiceFormEdit from '../components/service/ServiceFormEdit';

const ServiceEdit = () => {
    const { id } = useParams();
    return (
        <>
            <Helmet>
                <title>Edit network | Material Kit</title>
            </Helmet>
            <Box sx={{ backgroundColor: 'background.default', minHeight: '100%' }}>
                <Container maxWidth={false}>
                    <ServiceFormEdit id={String(id)} />
                </Container>
            </Box>
        </>
    );
};

export default ServiceEdit;
