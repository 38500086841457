import { Navigate } from 'react-router-dom';
import DashboardLayout from './components/DashboardLayout';
import DocumentAdd from './components/documents/DocumentAdd';
import DocumentEdit from './components/documents/DocumentEdit';
import MainLayout from './components/MainLayout';
import Account from './pages/Account';
import AdvertAdd from './pages/AdvertAdd';
import AdvertEdit from './pages/AdvertEdit';
import Adverts from './pages/Adverts';
import Broker from './pages/Broker';
import BrokerActiveGroupList from './pages/BrokerActiveGroupList';
import BrokerActiveMembersList from './pages/BrokerActiveMembersList';
import BrokerAdd from './pages/BrokerAdd';
import BrokerDeactiveGroupList from './pages/BrokerDeactiveGroupList';
import BrokerDeactiveMembersList from './pages/BrokerDeactiveMembersList';
import BrokerEdit from './pages/BrokerEdit';
import BrokerGroupList from './pages/BrokerGroupList';
import BrokerList from './pages/BrokerList';
import BrokerMembersList from './pages/BrokerMembersList';
import Customer from './pages/Customer';
import CustomerAdd from './pages/CustomerAdd';
import CustomerEdit from './pages/CustomerEdit';
import CustomerExport from './pages/CustomerExport';
import CustomerHistory from './pages/CustomerHistory';
import CustomerImport from './pages/CustomerImport';
import CustomerPaymentsHistory from './pages/CustomerPaymentsHistory';
import CustomerProducts from './pages/CustomerProducts';
import Customers from './pages/Customers';
import Dashboard from './pages/Dashboard';
import DbSpeedLogPage from './pages/DbSpeedLogPage';
import DocumentsList from './pages/DocumentsList';
import EmailRegistration from './pages/EmailRegistration';
import EmailTemplateCreate from './pages/EmailTemplateCreate';
import EmailTemplateEdit from './pages/EmailTemplateEdit';
import EmailTemplateList from './pages/EmailTemplateList';
import ExpiredCustomers from './pages/ExpiredCustomers';
import Group from './pages/Group';
import GroupAdd from './pages/GroupAdd';
import GroupEdit from './pages/GroupEdit';
import GroupExpirationDate from './pages/GroupExpirationDate';
import GroupExport from './pages/GroupExport';
import GroupImport from './pages/GroupImport';
import GroupList from './pages/GroupList';
import GroupSettings from './pages/GroupSettings';
import LogAppPage from './pages/LogAppPage';
import LogData from './pages/LogData';
import Login from './pages/Login';
import LogPage from './pages/LogPage';
import NotFound from './pages/NotFound';
import Products from './pages/Products';
import Register from './pages/Register';
import ServiceAdd from './pages/ServiceAdd';
import ServiceEdit from './pages/ServiceEdit';
import ServiceList from './pages/ServiceList';
import Settings from './pages/Settings';
import SubscriptionCreate from './pages/SubscriptionCreate';
import SubscriptionEdit from './pages/SubscriptionEdit';
import SubscriptionInfo from './pages/SubscriptionInfo';
import Subscriptions from './pages/Subscriptions';
import Validation from './pages/Validation';
import VurvIdValidation from './pages/VurvIdValidation';

const routes = [
  {
    path: 'app',
    element: <DashboardLayout />,
    children: [
      { path: 'account', element: <Account /> },
      { path: 'broker/:id', element: <Broker /> },
      { path: 'broker/add', element: <BrokerAdd /> },
      { path: 'broker/edit/:id', element: <BrokerEdit /> },
      { path: 'brokers', element: <BrokerList /> },
      { path: 'broker/group-list/:id', element: <BrokerGroupList /> },
      {
        path: 'broker/active-group-list/:id',
        element: <BrokerActiveGroupList />
      },
      {
        path: 'broker/deactive-group-list/:id',
        element: <BrokerDeactiveGroupList />
      },
      { path: 'broker/members-list/:id', element: <BrokerMembersList /> },
      {
        path: 'broker/active-members-list/:id',
        element: <BrokerActiveMembersList />
      },
      {
        path: 'broker/deactive-members-list/:id',
        element: <BrokerDeactiveMembersList />
      },
      { path: 'documents-list', element: <DocumentsList /> },
      { path: 'documents-add', element: <DocumentAdd /> },
      { path: 'documents-edit/:id', element: <DocumentEdit /> },
      { path: 'service/add', element: <ServiceAdd /> },
      { path: 'service/edit/:id', element: <ServiceEdit /> },
      { path: 'services', element: <ServiceList /> },
      { path: 'group/:id', element: <Group /> },
      { path: 'group/sittings', element: <GroupSettings /> },
      { path: 'group-import/:id', element: <GroupImport /> },
      { path: 'group-export/:id', element: <GroupExport /> },
      { path: 'group/add', element: <GroupAdd /> },
      { path: 'group/edit/:id', element: <GroupEdit /> },
      { path: 'group-expiration-date/:id', element: <GroupExpirationDate /> },
      { path: 'groups', element: <GroupList /> },
      { path: 'customer-import', element: <CustomerImport /> },
      { path: 'customer-export', element: <CustomerExport /> },
      { path: 'customer/:id', element: <Customer /> },
      { path: 'customer/:id/history', element: <CustomerHistory /> },
      {
        path: 'customer/:id/payments-history',
        element: <CustomerPaymentsHistory />
      },
      { path: 'customer/:id/customerProducts', element: <CustomerProducts /> },
      { path: 'customer/add', element: <CustomerAdd /> },
      { path: 'customer/edit/:id', element: <CustomerEdit /> },
      { path: 'expired-customers', element: <ExpiredCustomers /> },
      { path: 'customers', element: <Customers /> },
      { path: 'email-registration', element: <EmailRegistration /> },
      { path: 'email-templates', element: <EmailTemplateList /> },
      { path: 'email-templates/create', element: <EmailTemplateCreate /> },
      { path: 'email-templates/edit/:id', element: <EmailTemplateEdit /> },
      { path: 'vurv-id-validation', element: <VurvIdValidation /> },
      { path: 'dashboard', element: <Dashboard /> },
      // { path: 'products', element: <ProductList /> },
      { path: 'settings', element: <Settings /> },
      { path: 'adverts', element: <Adverts /> },
      { path: 'adverts/add/:id', element: <AdvertAdd /> },
      { path: 'adverts/edit/:id', element: <AdvertEdit /> },
      { path: 'logpage', element: <LogPage /> },
      { path: 'logapppage', element: <LogAppPage /> },
      { path: 'logpage/data', element: <LogData /> },
      { path: 'db-log', element: <DbSpeedLogPage /> },
      { path: 'subscriptions', element: <Subscriptions /> },
      { path: 'subscriptions/edit/:id', element: <SubscriptionEdit /> },
      { path: 'subscriptions/add', element: <SubscriptionCreate /> },
      { path: 'subscription-info/:id', element: <SubscriptionInfo /> },
      { path: 'products', element: <Products /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: 'login', element: <Login /> },
      { path: 'register', element: <Register /> },
      { path: 'validate', element: <Validation /> },
      { path: '404', element: <NotFound /> },
      { path: '/', element: <Navigate to="/app/dashboard" /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  }
];

export default routes;

