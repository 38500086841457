import {
  Avatar,
  Box,
  Button,
  Card,
  Chip,
  CircularProgress,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography
} from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import ReactHover, { Hover, Trigger } from 'react-hover';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Link as RouterLink } from 'react-router-dom';
import { useHttpDelete, usePost } from '../../request';
import { getColorChip, getLabelChip } from '../../utils/chip';
import getAvatar from '../../utils/getAvatar';
import getInitials from '../../utils/getInitials';
import { useAlert } from '../alert';
import { useConfirm } from '../confirm';
import CustomerCheckmark from './CustomerCheckmark';

const optionsCursorTrueWithMargin = {
  shiftX: 20,
  shiftY: 0
};

const CustomerListResults = ({
  filters,
  permissionToDelete,
  permissionToEdit,
  permissionToRecover,
  reloadCounter,
  children
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState(null);
  const [filterName, setFilterName] = useState('');
  const [filterId, setFilterId] = useState('');
  const [sortId, setSortId] = useState(false);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [count, setCount] = useState(0);
  const alert = useAlert();
  const post = usePost();
  const mounted = useRef(true);
  const httpDelete = useHttpDelete();
  const confirm = useConfirm();

  const loadUsers = () => {
    const requestData = {
      filters: {
        ...filters,
        order_desc: sortId
      },
      page,
      limit
    };

    if (filterName) {
      requestData.filters.name = filterName;
    }

    if (filterId) {
      requestData.filters.id = filterId;
    }

    setIsLoading(true);

    post('get-users-list', requestData)
      .then(async (response) => {
        if (response.result === 'success') {
          setUsers(response.users);
          setCount(response.count);
          setIsLoading(false);
        }
      })
      .catch((e) => console.log(e));
    setIsLoading(false);
  };

  const deleteUser = (id) => {
    confirm({
      title: 'Deleting',
      content: 'Are you sure you want to delete the user?',
      isDeleting: true,
      onConfirm: () => {
        httpDelete(`users/${id}`)
          .then((response) => {
            if (response.result === 'error') {
              alert({
                status: 'error',
                message: response.message
              });
              return;
            }
            loadUsers();
          })
          .catch((e) => console.log(e));
      }
    });
  };

  const recoverUser = (id) => {
    confirm({
      title: 'Recover',
      content:
        'Are you sure you want to recover the customer?He already was dropped from Group.',
      isDeleting: true,
      onConfirm: () => {
        post(`admin/recover-customer/${id}`)
          .then((response) => {
            if (response.result === 'error') {
              alert({
                status: 'error',
                message: response.message
              });
              return;
            }
            loadUsers();
          })
          .catch((e) => console.log(e));
      }
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeLimit = (event) => {
    setLimit(event.target.value);
    setPage(0);
  };

  const handleFilterName = (event) => {
    setFilterName(event.target.value);
  };

  const handleFilterId = (event) => {
    setFilterId(event.target.value);
  };

  const handleFilter = () => {
    loadUsers();
  };

  useEffect(() => {
    // Устанавливаем флаг mounted в true при монтировании компонента
    mounted.current = true;

    // Загрузка пользователей при монтировании компонента
    loadUsers();

    // Возвращаем функцию очистки useEffect для размонтирования компонента
    return () => {
      // Устанавливаем флаг mounted в false при размонтировании компонента
      mounted.current = false;
    };
  }, [page, limit, sortId, reloadCounter]);

  if (!users) {
    return (
      <Box
        sx={{
          height: 500,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Card>
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050 }}>
          <Box sx={{ display: 'flex', mx: 2 }}>
            <TextField
              fullWidth
              label="Name / Email / VurvID / StripeId"
              margin="normal"
              name="filterName"
              onChange={handleFilterName}
              type="text"
              value={filterName}
              variant="outlined"
            />
            <TextField
              fullWidth
              label="Id"
              margin="normal"
              name="filterId"
              onChange={handleFilterId}
              type="text"
              value={filterId}
              variant="outlined"
              sx={{ width: 100, mx: 1 }}
            />
            <Button
              color="primary"
              variant="contained"
              onClick={handleFilter}
              sx={{ mt: 2, mb: 1 }}
            >
              Filter
            </Button>
          </Box>
          {children}
          <Divider />
          {isLoading ? (
            <Box
              sx={{
                height: 400,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: 80 }}>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'flex-end',
                        color: '#5664d2'
                      }}
                    >
                      Id
                      <Button
                        sx={{ minWidth: 0, padding: 0, margin: 0.3 }}
                        onClick={() => setSortId((prev) => !prev)}
                      >
                        {sortId ? (
                          <ArrowDownwardIcon sx={{ fontSize: '1.2rem' }} />
                        ) : (
                          <ArrowUpwardIcon sx={{ fontSize: '1.2rem' }} />
                        )}
                      </Button>
                    </Box>
                  </TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>VurvID</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Phone</TableCell>
                  <TableCell>Expired date</TableCell>
                  <TableCell>Registration date</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {users.map((user) => (
                  <TableRow hover key={user.id}>
                    <TableCell sx={{ width: 80 }}>{user.id}</TableCell>
                    <TableCell>
                      <Box sx={{ alignItems: 'center', display: 'flex' }}>
                        <Box sx={{ position: 'relative' }}>
                          <Avatar src={getAvatar(user)} sx={{ mr: 2 }}>
                            {getInitials(
                              user.user_login
                                ? user.user_login
                                : `${user.user_last_name} ${user.user_first_name}`
                            )}
                          </Avatar>
                          {!!user.user_stripe_id &&
                            moment
                              .duration(
                                moment(new Date()).diff(
                                  moment(user.user_expired_date)
                                )
                              )
                              .asDays() > 15 && (
                              <Box
                                sx={{ position: 'absolute', top: 0, right: 10 }}
                              >
                                <ReactHover
                                  options={optionsCursorTrueWithMargin}
                                >
                                  <Trigger type="trigger">
                                    <ErrorOutlineIcon
                                      sx={{
                                        mx: 0.5,
                                        fontSize: 14,
                                        color: red[500]
                                      }}
                                      size={14}
                                    />
                                  </Trigger>
                                  <Hover type="hover">
                                    <div
                                      style={{
                                        background: '#6d6666',
                                        padding: '5px 10px',
                                        borderRadius: 10,
                                        fontSize: 12,
                                        color: '#fff',
                                        marginTop: 5,
                                        maxWidth: 200,
                                        width: 'max-content'
                                      }}
                                    >
                                      <p> Remove this user </p>
                                    </div>
                                  </Hover>
                                </ReactHover>
                              </Box>
                            )}
                        </Box>
                        <Typography color="textPrimary" variant="body1">
                          {user.user_login
                            ? user.user_login
                            : `${user.user_last_name} ${user.user_first_name}`}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Chip
                        color={getColorChip(user)}
                        label={getLabelChip(user)}
                      />
                    </TableCell>
                    <TableCell>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <div>{user.user_vurv_id}</div>
                        <div
                          style={{ display: 'flex', flexDirection: 'column' }}
                        >
                          <CustomerCheckmark
                            checked={user.user_vurv_id_is_active}
                            color={
                              user.user_vurv_id_is_active ? 'green' : 'grey'
                            }
                            title="Vurv"
                          />
                          <CustomerCheckmark
                            checked={user.in_newbenefits}
                            color={user.in_newbenefits ? 'black' : 'grey'}
                            title="NewBenefits"
                          />
                          <CustomerCheckmark
                            checked={user.is_recuro}
                            color={user.is_recuro ? 'indigo' : 'grey'}
                            title="Requro"
                          />
                        </div>
                      </Box>
                    </TableCell>
                    <TableCell>{user.user_email}</TableCell>
                    <TableCell>{user.user_phone}</TableCell>
                    <TableCell>{user.user_expired_date}</TableCell>
                    <TableCell>
                      {moment(user.user_registered).format('DD/MM/YYYY')}
                    </TableCell>
                    <TableCell>
                      <Box sx={{ display: 'flex' }}>
                        <RouterLink to={`/app/customer/${user.id}`}>
                          <Button color="primary" variant="contained">
                            Info
                          </Button>
                        </RouterLink>
                        {permissionToEdit && (
                          <Box sx={{ ml: 2 }}>
                            <RouterLink to={`/app/customer/edit/${user.id}`}>
                              <Button color="primary" variant="contained">
                                Edit
                              </Button>
                            </RouterLink>
                          </Box>
                        )}
                        {permissionToDelete && user.id > 1 && (
                          <Box sx={{ ml: 2 }}>
                            <Button
                              color="primary"
                              variant="contained"
                              onClick={() => deleteUser(user.id)}
                            >
                              Delete
                            </Button>
                          </Box>
                        )}
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[10, 20, 50, 100, 250]}
                    colSpan={7}
                    count={count}
                    rowsPerPage={limit}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeLimit}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          )}
        </Box>
      </PerfectScrollbar>
    </Card>
  );
};

CustomerListResults.propTypes = {
  filters: PropTypes.object,
  permissionToDelete: PropTypes.bool,
  permissionToEdit: PropTypes.bool,
  permissionToRecover: PropTypes.bool,
  reloadCounter: PropTypes.number,
  children: PropTypes.node
};

CustomerListResults.defaultProps = {
  filters: {},
  permissionToDelete: true,
  permissionToEdit: true,
  reloadCounter: 0
};

export default CustomerListResults;

