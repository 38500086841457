import { useState } from 'react';
import { configureTransport } from '../../enviroment';

const UserData = () => {
    /*
    const [login, setLogin] = useState('guest');

    return {
        getLogin: () => login,
        setLogin: () => setLogin
    };
    */
    const LoginKeyName = 'UserDataLogin';
    configureTransport(localStorage.getItem('access_token'));
    const data = {
        login: localStorage.getItem(LoginKeyName),
        access_token: localStorage.getItem('access_token'),
        refresh_token: localStorage.getItem('refresh_token'),
        setToken: (access_token, refresh_token) => {
            configureTransport(access_token);
            localStorage.setItem('access_token', access_token);
            localStorage.setItem('refresh_token', refresh_token);
        },
        setLogin: (name) => {
            localStorage.setItem(LoginKeyName, name);
        },
        logOut: () => {
            configureTransport();
            localStorage.removeItem(LoginKeyName);
        }
    };

    return data;
};

export default UserData;
