import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
    Box,
    Card,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Button
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';

const ServiceListResults = ({ services, deleteService, ...rest }) => (
    <Card {...rest}>
        <PerfectScrollbar>
            <Box sx={{ minWidth: 1050 }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                Name
                            </TableCell>
                            <TableCell />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {services.map((service) => (
                            <TableRow key={service.id}>
                                <TableCell>
                                    {service.name}
                                </TableCell>
                                <TableCell>
                                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        <RouterLink to={`/app/service/edit/${service.id}`}>
                                            <Button color="primary" variant="contained">
                                                Edit
                                            </Button>
                                        </RouterLink>
                                        <Box sx={{ ml: 2 }}>
                                            <Button color="primary" variant="contained" onClick={() => deleteService(service.id)}>
                                                Delete
                                            </Button>
                                        </Box>
                                    </Box>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Box>
        </PerfectScrollbar>
    </Card>
);

ServiceListResults.propTypes = {
    services: PropTypes.array.isRequired,
    deleteService: PropTypes.func.isRequired,
};

export default ServiceListResults;
