import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import {
    Box, Container, Typography, Table, TableBody, TableCell, TableHead, TableRow
} from '@material-ui/core';

const DbSpeedLog = ({ logs }) => {
    if (logs.length === 0) {
        return (
            <Typography color="textPrimary" variant="h3" component="h1">No database logs</Typography>
        );
    }

    return (
        <Table sx={{ backgroundColor: '#fff', boxShadow: '0 0 0 1px rgb(63, 63, 68, 0.1)', borderRadius: '4px' }}>
            <TableHead>
                <TableRow>
                    <TableCell>
                        Database query
                    </TableCell>
                    <TableCell>
                        Execution time
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                { logs.map((item) => (
                    <TableRow key={item.id}>
                        <TableCell>
                            {item.query}
                        </TableCell>
                        <TableCell>
                            {item.time}
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
};

DbSpeedLog.propTypes = {
    logs: PropTypes.array.isRequired
};

export default DbSpeedLog;
