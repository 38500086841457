import React, { useState } from 'react';
import DoneIcon from '@material-ui/icons/Done';
import ClearIcon from '@material-ui/icons/Clear';
import CircularProgress from '@material-ui/core/CircularProgress';
import PropTypes from 'prop-types';
import {
    green,
    red,
    grey,
    blue,
    indigo
} from '@material-ui/core/colors';

const accent = grey['800'];
const CustomerCheckmark = ({ checked, color, title }) => {
  const [isHovered, setIsHovered] = useState(false);

  const [greenColor, redColor, blueColor, indigoColor, defaultColor] = [
    green[500],
    red[500],
    blue[500],
    indigo[500],
    grey[500]
  ];
  let iconColor;

  if (color === 'green') {
    iconColor = greenColor;
  } else if (color === 'red') {
    iconColor = redColor;
  } else if (color === 'blue') {
    iconColor = blueColor;
  } else if (color === 'black') {
    iconColor = accent;
  } else if (color === 'indigo') {
    iconColor = indigo;
  } else {
    iconColor = defaultColor;
  }

  let icon;

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  if (checked) {
    icon = (
      <DoneIcon
        sx={{
          color: iconColor,
          fontSize: 14,
          strokeWidth: 2,
          stroke: iconColor,
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      />
    );
  } else {
    icon = (
      <ClearIcon
        sx={{
          color: red[500],
          fontSize: 14,
          strokeWidth: 2,
          stroke: red[500],
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      />
    );
  }

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div style={{ marginRight: '10px' }}>
        {icon}
      </div>
      {isHovered && (
        <div style={{
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
            color: '#fff',
            padding: '5px 10px',
            borderRadius: '4px',
            position: 'absolute',
            transform: 'translateX(25px)',
        }}
        >
          {title}
        </div>
      )}
    </div>
  );
};

CustomerCheckmark.propTypes = {
  checked: PropTypes.any,
  color: PropTypes.string,
  title: PropTypes.string,
};

CustomerCheckmark.defaultProps = {
  checked: null,
  color: grey[500],
  title: '',
};

export default CustomerCheckmark;
