import { useState, useEffect } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Helmet } from 'react-helmet';
import {
    Box,
    Container,
    Button,
    Breadcrumbs,
    Typography,
    CircularProgress,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Card
} from '@material-ui/core';

import ChevronLeft from '@material-ui/icons/ChevronLeft';
import { Link as RouterLink, useParams, useNavigate } from 'react-router-dom';
import {
    useGet,
} from '../request';

const CustomerProducts = () => {
    const { id } = useParams();
    const [customer, setCustomer] = useState(null);
    const [products, setProducts] = useState([]);
    const get = useGet();
    const navigate = useNavigate();

    useEffect(() => {
        get(`get-user/${id}`)
        .then((response) => {
            if (response.result !== 'success') {
                return;
            }
            setCustomer(response.user);
        })
        .catch((e) => console.log(e));
        get(`admin/user/get-vurv-products/${id}`)
        .then((response) => {
            if (response.result !== 'success') {
                return;
            }
            console.log(response)
            setProducts(response?.purchasedProducts || []);
        })
        .catch((e) => console.log(e));
    }, []);

    let totalAmount = 0;
    products.forEach((product) => {
    totalAmount += product.amount;
    });

    if (!customer) {
        return (
            <Box sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: 100
            }}
            >
                <CircularProgress />
            </Box>
        );
    }

    return (
        <>
            <Helmet>
                <title>Customer Products | Material Kit</title>
            </Helmet>
            <Box sx={{ backgroundColor: 'background.default', minHeight: '100%' }}>
                <Container maxWidth={false}>
                    <Box sx={{ py: 2 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', pb: 1 }}>
                            <Box style={{ marginTop: 3, marginRight: 10 }}>
                                <Button startIcon={<ChevronLeft />} onClick={() => navigate(-1)}>
                                    Back
                                </Button>
                            </Box>
                            <Typography color="textPrimary" variant="h4" component="h1">
                                Customer Products
                            </Typography>
                        </Box>
                        <Breadcrumbs aria-label="breadcrumb" style={{ fontSize: 12 }}>
                            <RouterLink underline="hover" color="inherit" to="/app/customers">
                                Customers
                            </RouterLink>
                            <RouterLink underline="hover" color="inherit" to={`/app/customer/${id}`}>
                                {`${customer.user_first_name} ${customer.user_last_name}`}
                            </RouterLink>
                            <Typography color="textPrimary">Customer Products</Typography>
                        </Breadcrumbs>
                    </Box>
                    <Card sx={{ maxWidth: 900, margin: 'auto' }}>
                        <PerfectScrollbar>
                            <Box sx={{ minWidth: 800 }}>
                                <Table>
                                <TableHead>
                                    <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Amount</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {products.map((product) => (
                                    <TableRow key={product.id}>
                                        <TableCell>{product.name}</TableCell>
                                        <TableCell>{product.amount}</TableCell>
                                    </TableRow>
                                    ))}
                                    <TableRow sx={{ backgroundColor: '#e0e0e0' }}>
                                        <TableCell sx={{ fontWeight: 'bold' }}>Total Amount</TableCell>
                                        <TableCell sx={{ fontWeight: 'bold' }}>{totalAmount}</TableCell>
                                    </TableRow>
                                </TableBody>
                                </Table>
                            </Box>
                        </PerfectScrollbar>
                    </Card>
                </Container>
            </Box>
        </>
    );
};

export default CustomerProducts;
