import React from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography
} from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import getPrice from '../utils/getPrice';

const ProductsTable = function ({ products }) {
    const navigate = useNavigate();

    if (products.length === 0) {
        return (
            <Typography color="textPrimary" variant="body1" component="p" sx={{ p: 2, textAlign: 'center', backgroundColor: '#fff' }}>
                Products not found
            </Typography>
        );
    }

    const showIsConfigured = (flag) => {
        let style = { width: 150 };
        if (!flag) {
            return (
                <TableCell sx={{ color: 'red', ...style }}>
                    ✕ Not active
                </TableCell>
            );
        }
        return (
            <TableCell sx={{ color: 'green', ...style }}>
                ✓ Connected
            </TableCell>
        );
    }

    return (
        <Box>
            <Table sx={{ backgroundColor: '#fff', boxShadow: '0 0 0 1px rgb(63, 63, 68, 0.1)', borderRadius: '4px' }}>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            Name
                        </TableCell>
                        <TableCell sx={{ width: 200 }}>
                            Id
                        </TableCell>
                        <TableCell sx={{ width: 150 }}>
                            Is configured
                        </TableCell>
                        <TableCell sx={{ width: 300 }}>
                            Subscripton (Vurv)
                        </TableCell>
                        <TableCell sx={{ width: 200 }}>
                            Plan id
                        </TableCell>
                        <TableCell>
                            Amount ($)
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        products.map((item) => (
                            <TableRow key={item.id}>
                                <TableCell>
                                    {item.name}
                                </TableCell>
                                <TableCell sx={{ width: 200 }}>
                                    {item.id}
                                </TableCell>
                                {showIsConfigured(item.subscription?.id)}
                                <TableCell sx={{ width: 300 }}>
                                    {item.subscription?.name}
                                </TableCell>
                                <TableCell sx={{ width: 200 }}>
                                    {item.plan_id}
                                </TableCell>
                                <TableCell>
                                    {getPrice(item.prices.amount)}
                                </TableCell>
                            </TableRow>
                        ))
                    }
                </TableBody>
            </Table>
        </Box>
    );
};

ProductsTable.propTypes = {
    products: PropTypes.array.isRequired
};

export default ProductsTable;
