export const getColorChip = (user) => {
  if (user) {
    if (user.is_free_user === 1) return 'success';
    else return 'default';
  }

  return 'default';
};

export const getLabelChip = (user) => {
  if (user) {
    if (user.is_free_user === 1) return 'Free';
    else return 'Default';
  }

  return 'Default';
};

