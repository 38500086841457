import { Helmet } from 'react-helmet';
import {
    Box,
    Container
} from '@material-ui/core';
import { useParams } from 'react-router-dom';
import GroupFormEdit from '../components/group/GroupFormEdit';

const GroupEdit = () => {
    const { id } = useParams();
    return (
        <>
            <Helmet>
                <title>Edit group | Material Kit</title>
            </Helmet>
            <Box sx={{ backgroundColor: 'background.default', minHeight: '100%' }}>
                <Container maxWidth={false}>
                    <GroupFormEdit id={String(id)} />
                </Container>
            </Box>
        </>
    );
};

export default GroupEdit;
