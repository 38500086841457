import {
  Box,
  Breadcrumbs,
  Button,
  Container,
  Typography
} from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import CustomerListResults from '../components/customer/CustomerListResults';

const ExpiredCustomers = () => {
  const navigate = useNavigate();
  return (
    <>
      <Helmet>
        <title>Archive Customers | Material Kit</title>
      </Helmet>
      <Box sx={{ backgroundColor: 'background.default', minHeight: '100%' }}>
        <Container maxWidth={false}>
          <Box sx={{ py: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', pb: 1 }}>
              <Box style={{ marginTop: 3, marginRight: 10 }}>
                <Button
                  startIcon={<ChevronLeft />}
                  onClick={() => navigate(-1)}
                >
                  Back
                </Button>
              </Box>
              <Typography color="textPrimary" variant="h4" component="h1">
                Archive Customers
              </Typography>
            </Box>
            <Breadcrumbs aria-label="breadcrumb" style={{ fontSize: 12 }}>
              <p>Archive Customers</p>
            </Breadcrumbs>
          </Box>
          <CustomerListResults
            filters={{ soft_delete: 1 }}
            permissionToDelete={false}
            permissionToEdit={false}
            permissionToRecover
          />
        </Container>
      </Box>
    </>
  );
};

export default ExpiredCustomers;

