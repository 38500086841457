import { Helmet } from 'react-helmet';
import {
    Box,
    Container,
} from '@material-ui/core';
import CustomerFormCreate from '../components/customer/CustomerFormCreate';

const CustomerAdd = () => (
    <>
        <Helmet>
            <title>Create new customer | Material Kit</title>
        </Helmet>
        <Box sx={{ backgroundColor: 'background.default', minHeight: '100%' }}>
            <Container maxWidth={false}>
                <CustomerFormCreate />
            </Container>
        </Box>
    </>
);

export default CustomerAdd;
