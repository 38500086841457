import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import {
    Box,
    Container,
    Button,
    Breadcrumbs,
    Typography,
    CircularProgress
} from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import BrokerListResults from '../components/broker/BrokerListResults';
import { useAlert } from '../components/alert';
import {
    useGet,
    useHttpDelete
} from '../request';
import { useConfirm } from '../components/confirm';

const BrokerList = () => {
    const [brokerList, setBrokerList] = useState(null);
    const get = useGet();
    const httpDelete = useHttpDelete();
    const navigate = useNavigate();
    const alert = useAlert();
    const confirm = useConfirm();

    const loadBrokers = () => {
        get('admin/brokers')
        .then((response) => {
            if (response.result === 'success') {
                setBrokerList(response.brokers);
            }
        })
        .catch((e) => console.log(e));
    };

    useEffect(() => {
        loadBrokers();
    }, []);

    const deleteBroker = (id) => {
        confirm({
            title: 'Deleting',
            content: 'Are you sure you want to delete the broker?',
            isDeleting: true,
            onConfirm: () => {
                httpDelete(`admin/brokers/${id}`)
                .then((response) => {
                    if (response.result === 'error') {
                        alert({
                            status: 'error',
                            message: response.message
                        });
                        return;
                    }
                    loadBrokers()
                })
                .catch((e) => console.log(e));
            }
        });
    };

    if (!brokerList) {
        return (
            <Box sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: 100
            }}
            >
                <CircularProgress />
            </Box>
        );
    }

    return (
        <>
            <Helmet>
                <title>Brokers | Material Kit</title>
            </Helmet>
            <Box sx={{ backgroundColor: 'background.default', minHeight: '100%' }}>
                <Container maxWidth={false}>
                    <Box sx={{ py: 2 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', pb: 1 }}>
                            <Box style={{ marginTop: 3, marginRight: 10 }}>
                                <Button startIcon={<ChevronLeft />} onClick={() => navigate(-1)}>
                                    Back
                                </Button>
                            </Box>
                            <Typography color="textPrimary" variant="h4" component="h1">
                                Brokers
                            </Typography>
                            <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: 'auto' }}>
                                <RouterLink to="/app/broker/add">
                                    <Button color="primary" variant="contained">
                                        Add broker
                                    </Button>
                                </RouterLink>
                            </Box>
                        </Box>
                        <Breadcrumbs aria-label="breadcrumb" style={{ fontSize: 12 }}>
                            <p>Brokers</p>
                        </Breadcrumbs>
                    </Box>
                    <BrokerListResults brokers={brokerList} deleteBroker={deleteBroker} />
                </Container>
            </Box>
        </>
    );
};

export default BrokerList;
