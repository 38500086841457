import {
  Avatar,
  Box,
  Divider,
  Drawer,
  List,
  Typography
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import {
  BarChart as BarChartIcon,
  CheckCircle as CheckCircleIcon,
  Database as DataBaseIcon,
  Edit as EditIcon,
  HardDrive as GrSystemIcon,
  Info as InfoIcon,
  Mail as MailIcon,
  Settings as SettingsIcon,
  ShoppingBag as ShoppingBagIcon,
  Users as UsersIcon
} from 'react-feather';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import NavItem from './NavItem';

const user = {
  avatar: '/static/images/avatars/avatar_6.png',
  jobTitle: 'Senior Developer',
  name: 'Admin'
};

const itemsDesign = [
  {
    href: '/app/dashboard',
    icon: BarChartIcon,
    title: 'Dashboard'
  },
  {
    icon: UsersIcon,
    title: 'Customers',
    list: [
      {
        href: '/app/customers',
        icon: UsersIcon,
        title: 'Customers'
      },
      {
        href: '/app/expired-customers',
        icon: UsersIcon,
        title: 'Archive Customers'
      }
    ]
  },
  {
    href: '/app/adverts',
    icon: EditIcon,
    title: 'Provider ad'
  }
];

const itemsLists = [
  {
    icon: UsersIcon,
    title: 'Subscriptions',
    list: [
      {
        href: '/app/subscriptions',
        icon: UsersIcon,
        title: 'List of subscriptions'
      },
      {
        href: '/app/products',
        icon: UsersIcon,
        title: 'Products'
      }
    ]
  },
  {
    href: '/app/brokers',
    icon: UsersIcon,
    title: 'Brokers'
  },
  {
    href: '/app/services',
    icon: ShoppingBagIcon,
    title: 'Networks'
  },
  {
    href: '/app/groups',
    icon: UsersIcon,
    title: 'Groups'
  }
];

const itemsSystem = [
  {
    icon: MailIcon,
    title: 'Email',
    list: [
      {
        href: '/app/email-registration',
        icon: MailIcon,
        title: 'Users registration (left)'
      },
      {
        href: '/app/email-templates',
        icon: MailIcon,
        title: 'Templates'
      }
    ]
  },
  {
    href: '/app/vurv-id-validation',
    icon: CheckCircleIcon,
    title: 'Vurv id validation'
  },
  {
    href: '/app/logpage',
    icon: InfoIcon,
    title: 'Log'
  },
  {
    href: '/app/documents-list',
    icon: EditIcon,
    title: 'Documents'
  },
  {
    icon: GrSystemIcon,
    title: 'System',
    list: [
      {
        href: '/app/db-log',
        icon: DataBaseIcon,
        title: 'DB speed log'
      },
      {
        href: '/app/settings',
        icon: SettingsIcon,
        title: 'Settings'
      }
    ]
  }
];

const DashboardSidebar = ({ onMobileClose, openMobile }) => {
  const location = useLocation();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          p: 2
        }}
      >
        <Avatar
          component={RouterLink}
          src={user.avatar}
          sx={{
            cursor: 'pointer',
            width: 64,
            height: 64
          }}
          to="/app/account"
        />
        <Typography color="textPrimary" variant="h5">
          {user.name}
        </Typography>
        <Typography color="textSecondary" variant="body2">
          {user.jobTitle}
        </Typography>
      </Box>
      <Divider />
      <Box sx={{ px: 2 }}>
        <List>
          {itemsDesign.map((item) => (
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
              list={item?.list}
            />
          ))}
        </List>
      </Box>
      <Divider />
      <Box sx={{ px: 2 }}>
        <List>
          {itemsLists.map((item) => (
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
              list={item?.list}
            />
          ))}
        </List>
      </Box>
      <Divider />
      <Box sx={{ px: 2 }}>
        <List>
          {itemsSystem.map((item) => (
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
              list={item?.list}
            />
          ))}
        </List>
      </Box>
      <Box sx={{ flexGrow: 1 }} />
    </Box>
  );

  return (
    <>
      <Box sx={{ display: { xs: 'block', lg: 'none' } }}>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
          PaperProps={{
            sx: {
              width: 256
            }
          }}
        >
          {content}
        </Drawer>
      </Box>
      <Box sx={{ display: { xs: 'none', lg: 'block' } }}>
        <Drawer
          anchor="left"
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: 256,
              top: 64,
              height: 'calc(100% - 64px)'
            }
          }}
        >
          {content}
        </Drawer>
      </Box>
    </>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

DashboardSidebar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false
};

export default DashboardSidebar;

