import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import {
  Box,
  Container,
  Button,
  Alert,
  CardHeader,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Breadcrumbs,
  Typography,
  Avatar,
  Card,
  CardContent,
  CircularProgress,
} from '@material-ui/core';
import ReactQuill from 'react-quill';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import {
    useGet,
    useHttpDelete,
    usePut,
    usePost
} from '../../request';
import 'react-quill/dist/quill.snow.css';
import '../../styles/All.css';

const modules = {
  toolbar: [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ['bold', 'italic', 'underline', 'strike'],
    ['link', 'image'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    ['clean'],
  ],
};

const DocumentEdit = () => {
    const [context, setContext] = useState('');
    const [docs_key, setDocsKey] = useState('');
    const postM = usePost();
    const navigate = useNavigate();
    const put = usePut();
    const [alert, setAlert] = useState({
        txt: '',
        isVisible: false,
        type: 'error'
    });
    const [submitDisabled, setSubmitDisabled] = useState(false);
    const getI = useGet();
    const post = usePost();
    const [originalText, setOriginalText] = useState()
    const { id } = useParams();
    const [isEdit, setIsEdit] = useState(false);
    const [text, setText] = useState('')

    const [isLoaded, setIsLoaded] = useState(true);

    const [instruction, setInstruction] = useState({});

    const showAlert = (type, textAllert) => {
      setAlert({
        txt: textAllert,
        type,
        isVisible: true
      });

      setTimeout(() => {
        setAlert({
          txt: '',
          type,
          isVisible: false
        });
      }, 1400);
    };

    const saveChanges = () => {
      const newText = {
        context: text
      }

      if (newText.context === '<p><br></p>') {
          showAlert('error', 'Document must not be empty');
      }

      if (newText.context !== '<p><br></p>'
          && newText !== ''
      ) {
          post(`admin/docs/${id}`, newText)
          .then((resp) => {
            if (resp.result === 'success') {
              showAlert('success', 'Document upload');
            } else {
              showAlert('error', 'Error');
            }
          })
          .catch((err) => {
            console.log(err);
            showAlert('error', 'Server Error');
          })
          .finally(() => {});
      }
  }

  const typeDocument = (type) => {
    if (type === 1) {
        return 'Membership_Agreement'
    }
    if (type === 2) {
        return 'Privacy_Policy'
    }
    if (type === 3) {
        return 'Terms_and_conditions'
    }
    return null
}

  useEffect(() => {
    setIsLoaded(true);
    getI(`admin/get-docs/${id}`)
      .then((resp) => {
        if (resp.result === 'success') {
          setInstruction(resp.dataDoc);
          setOriginalText(resp.dataDoc.context)
        }
      })
      .catch(() => {})
      .finally(() => {
        setIsLoaded(false);
      });
  }, []);

  useEffect(() => {
    setText(originalText);
  }, [originalText])

  const formattedDateTime = (createdAt) => {
    const dateObj = new Date(createdAt);
    return dateObj.toLocaleString('ru-RU', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit'
  });
}

  if (isLoaded) {
    return (
      <div className="loader">
        <CircularProgress
          height="100"
          width="100"
          color="grey"
          ariaLabel="loading"
        />
      </div>
    );
  }

    return (
        <>
            <Helmet>
              <title>Document info</title>
            </Helmet>
            <Box className="headerWrapper">
              <Box className="headerTitle" sx={{ width: '92%', display: 'flex', justifyContent: 'space-between' }}>
              <Box sx={{ py: 2 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', pb: 1 }}>
                  <Box style={{ marginTop: 3, marginRight: 10 }}>
                      <Button startIcon={<ChevronLeft />} onClick={() => navigate(-1)}>
                          Back
                      </Button>
                  </Box>
                  <Typography color="textPrimary" variant="h4" component="h1">
                    Info Document
                  </Typography>
                </Box>
                <Breadcrumbs aria-label="breadcrumb" style={{ fontSize: 12 }}>
                    <RouterLink underline="hover" color="inherit" to="/app/documents-list">
                      Documents List
                    </RouterLink>
                      <p>Info Document</p>
                </Breadcrumbs>
              </Box>
              </Box>
            </Box>
            <Alert
              severity={alert.type}
              style={{ display: alert.isVisible ? 'flex' : 'none' }}
            >
              {alert.txt}
            </Alert>
            <Box
              sx={{
                width: '90%',
                height: '100%',
                marginTop: '2%',
                marginLeft: '2%',
                backgroundColor: 'white',
                borderRadius: '5px',
                border: '1px solid #6b778c83',
              }}
            >
              <PerfectScrollbar style={{ overflow: 'hidden' }}>
              <div
                className="outer-container"
                style={{
                height: '85vh',
                display: 'flex',
                flexDirection: 'column',
                }}
              >
                  <div
                    className="wrapAvatar"
                    style={{
                    marginTop: '0px',
                    }}
                  >
                    <div className="wrap">
                      <div className="label">ID:</div>
                      <div className="text">{instruction?.id || '---'}</div>
                    </div>
                    <div className="wrap">
                      <div className="label">User ID:</div>
                      <div className="text">{instruction?.user_id || '---'}</div>
                    </div>
                    <div className="wrap">
                      <div className="label">Type document:</div>
                      <div className="text">{typeDocument(instruction.docs_key) || '---'}</div>
                    </div>
                    <div className="wrap">
                      <div className="label">Date create:</div>
                      <div className="text">{formattedDateTime(instruction.date_create) || '---'}</div>
                    </div>
                    <div className="wrap">
                      <div className="label">Date update:</div>
                      <div className="text">{instruction.date_update ? formattedDateTime(instruction.date_update) : '---'}</div>
                    </div>
                    <div className="wrap">
                      <div className="label">Content:</div>
                    </div>
                  </div>
                <ReactQuill
                  theme="snow"
                  style={{
                    height: '40%',
                    marginTop: '0px',
                    marginLeft: '20px',
                    width: '96%'
                  }}
                  value={text}
                  onChange={setText}
                  readOnly={!isEdit}
                />
                <Box sx={{
                  position: 'sticky',
                  bottom: 10,
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginTop: '55px',
                  marginLeft: '1000px',
                  marginRight: '20px',
                }}
                >
                  {isEdit ? (
                    <Button
                      style={{ height: '50px', width: '190px' }}
                      variant="contained"
                      color="success"
                      onClick={() => {
                        saveChanges();
                        setIsEdit(false);
                      }}
                    >
                      Save
                    </Button>
                  ) : (
                    <Button
                      style={{ height: '50px', width: '190px' }}
                      color="primary"
                      variant="contained"
                      onClick={() => setIsEdit(true)}
                    >
                      Edit
                    </Button>
                  )}
                </Box>
              </div>
              </PerfectScrollbar>
            </Box>
        </>
    )
}

export default DocumentEdit;
