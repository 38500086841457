/* eslint-disable */
import { useState, useEffect, useLayoutEffect } from 'react';
import { Doughnut } from 'react-chartjs-2';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  colors,
  useTheme
} from '@material-ui/core';
import LaptopMacIcon from '@material-ui/icons/LaptopMac';
import PhoneIcon from '@material-ui/icons/Phone';
import TabletIcon from '@material-ui/icons/Tablet';
import { useGet, usePost } from '../../request';

const CountUsers = ({ sx, userStats }) => {
  const theme = useTheme();
  const get = useGet();
  const [totalUsers, setTotalUsers] = useState(0);

  const [data, setData] = useState({
    datasets: [
      {
        data: [0, 0, 0],
        backgroundColor: [
          colors.indigo[500],
          colors.green[600],
          colors.grey[500],
          colors.green[600]
        ],
        borderWidth: 8,
        borderColor: colors.common.white,
        hoverBorderColor: colors.common.white
      }
    ],
    labels: []
  });

  const options = {
    animation: true,
    layout: { padding: 0 },
    legend: {
      display: false
    },
    maintainAspectRatio: false,
    responsive: true,
    tooltips: {
      backgroundColor: theme.palette.background.paper,
      bodyFontColor: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      borderWidth: 1,
      enabled: true,
      footerFontColor: theme.palette.text.secondary,
      intersect: false,
      mode: 'index',
      titleFontColor: theme.palette.text.primary,
      spacing: 0.1
    }
  };

  const [devices, setDevices] = useState([
    {
      title: 'Non Primary',
      value: 0,
      color: colors.indigo[500]
    },
    {
      title: 'Active',
      value: 0,
      icon: TabletIcon,
      color: colors.green[600]
    },
    {
      title: 'Deactive',
      value: 0,
      color: colors.grey[500]
    }
  ]);

  useLayoutEffect(() => {
    if (!userStats) return;
    setTotalUsers(userStats.total);
    setDevices((prevDevices) => [
      {
        ...prevDevices[0],
        value: userStats.nonPrimary || 0
      },
      {
        ...prevDevices[1],
        value: userStats.activePaymentCount || 0
      },
      {
        ...prevDevices[2],
        value:
          userStats.total -
            userStats.nonPrimary -
            userStats.activePaymentCount || 0
      }
    ]);

    setData((prevData) => ({
      ...prevData,
      datasets: [
        {
          ...prevData.datasets[0],
          data: [
            userStats.nonPrimary || 0,
            userStats.activePaymentCount || 0,
            userStats.total -
              userStats.nonPrimary -
              userStats.activePaymentCount || 0
          ]
        }
      ]
    }));
  }, [userStats]);

  return (
    <Card {...sx} sx={{ marginBottom: '25px' }} style={{ height: '100%' }}>
      <CardHeader title="Number of users" />
      <Divider />
      <CardContent>
        <Box
          sx={{
            height: 300,
            position: 'relative'
          }}
        >
          <Box style={{ minHeight: 12 }}></Box>
          <Doughnut data={data} options={options} />
        </Box>
        <Box style={{ minHeight: 20 }}></Box>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            pt: 0,
            rowGap: 20
          }}
        >
          {devices.map(({ color, title, value }) => (
            <Box
              key={title}
              sx={{
                p: 1,
                textAlign: 'center'
              }}
            >
              <Typography color="textPrimary" variant="body1">
                {title}
              </Typography>
              <Typography style={{ color }} variant="h2">
                {value}
              </Typography>
            </Box>
          ))}
          <Box
            sx={{
              p: 1,
              textAlign: 'center'
            }}
          >
            <Typography color="textPrimary" variant="body1">
              Total Users
            </Typography>
            <Typography variant="h2">{totalUsers}</Typography>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default CountUsers;
