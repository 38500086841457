/* eslint-disable */
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Avatar,
  TextField,
  CardContent,
  CardHeader,
  Card,
  Divider,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  CircularProgress
} from '@material-ui/core';
import { useGet, usePost } from '../../request';
import CustomerAlert from './CustomerAlert';
import { uploadURL } from '../../enviroment';
import { CustomerSelectCountry } from './CustomerSelectCountry';
import { CustomerSelectState } from './CustomerSelectState';

const CustomerFormEdit = (props) => {
  const { id } = props;
  const [values, setValues] = useState({
    firstName: '',
    lastName: '',
    email: '',
    gender: 'Female',
    phone: '',
    street: '',
    city: '',
    state: '',
    postalCode: '',
    country: 'US'
  });
  // const [image, setImage] = useState('');
  // const [editedImage, setEditedImage] = useState('');
  const [isValidateEmail, setIsValidateEmail] = useState(true);
  const [isShowLoader, setIsShowLoader] = useState(false);
  const [isLoad, setIsLoad] = useState(true);
  const [alert, setAlert] = useState({
    status: '',
    message: ''
  });
  const post = usePost();
  const get = useGet();

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const handleChangeEmail = (event) => {
    const reg =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    setIsValidateEmail(!!event.target.value.match(reg));
    setValues({
      ...values,
      email: event.target.value
    });
  };

  // const handleChangeImage = (event) => {
  //   const file = event.target.files[0];
  //   setEditedImage(file);
  //   const reader = new FileReader();
  //   reader.onload = (e) => {
  //       setImage(e.target.result);
  //       reader.abort();
  //   };
  //   reader.readAsDataURL(file);
  // };

  const submit = async () => {
    // if (editedImage) {
    //     console.log(editedImage);
    //     const formData = new FormData();
    //     formData.append('file', editedImage);

    //     const headers = {
    //       'Content-Type': 'multipart/form-data'
    //     };

    //     await post('upload-avatar', formData, { headers })
    //     .then((response) => console.log(response))
    //     .catch((e) => console.log(e));
    // }
    setIsShowLoader(true);
    const requestData = {
      user_first_name: values.firstName,
      user_last_name: values.lastName,
      user_email: values.email,
      user_gender: values.gender,
      user_phone: values.phone,
      user_address_line1: values.street,
      user_address_city: values.city,
      user_address_state: values.state,
      user_address_postal_code: values.postalCode,
      user_address_country: values.country
    };

    // console.log(requestData);

    post(`update-user/${id}`, requestData)
      .then((response) => {
        setIsShowLoader(false);
        setAlert({
          status: response.result,
          message: response.message
        });
      })
      .catch((e) => {
        console.log(e);
        setIsShowLoader(false);
      });
  };

  useEffect(() => {
    if (isLoad) {
      get(`get-user/${id}`)
        .then((response) => {
          if (response.result !== 'success') {
            setIsLoad(false);
            return;
          }

          let data = {};

          // if (response.user.user_first_name) {
          //   setImage(`${uploadURL}${response.user.user_avatar_url}`);
          // } else {
          //   setImage('https://www.seekpng.com/png/detail/428-4287240_no-avatar-user-circle-icon-png.png');
          // }

          if (response.user.user_first_name) {
            data.firstName = response.user.user_first_name;
          }

          if (response.user.user_last_name) {
            data.lastName = response.user.user_last_name;
          }

          if (response.user.user_email) {
            data.email = response.user.user_email;
          }

          if (response.user.user_gender) {
            data.gender = response.user.user_gender;
          }

          if (response.user.user_phone) {
            data.phone = response.user.user_phone;
          }

          if (response.user.user_address_line1) {
            data.street = response.user.user_address_line1;
          }

          if (response.user.user_address_city) {
            data.city = response.user.user_address_city;
          }

          if (response.user.user_address_state) {
            data.state = response.user.user_address_state;
          }

          if (response.user.user_address_postal_code) {
            data.postalCode = response.user.user_address_postal_code;
          }

          if (response.user.user_address_country) {
            data.country = response.user.user_address_country;
          }

          setIsLoad(false);
          setValues({ ...values, ...data });
        })
        .catch((e) => console.log(e));
    }
  });

  // <Avatar src={image} sx={{ mr: 2 }}>
  //     Avatar
  // </Avatar>
  // <input type="file" onChange={handleChangeImage} />
  return (
    <form>
      <Card>
        <CardHeader title="Edit customer" />
        <Divider />
        <CardContent sx={{ position: 'relative' }}>
          {isShowLoader && (
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: 5
              }}
            >
              <CircularProgress />
            </Box>
          )}
          <TextField
            fullWidth
            label="First name *"
            margin="normal"
            name="firstName"
            onChange={handleChange}
            type="text"
            value={values.firstName}
            variant="outlined"
          />
          <TextField
            fullWidth
            label="Last name *"
            margin="normal"
            name="lastName"
            onChange={handleChange}
            type="text"
            value={values.lastName}
            variant="outlined"
          />
          <TextField
            fullWidth
            label="Email *"
            margin="normal"
            name="email"
            onChange={handleChangeEmail}
            type="email"
            value={values.email}
            variant="outlined"
            error={!isValidateEmail}
          />
          <FormControl fullWidth sx={{ mt: 2, mb: 1 }}>
            <InputLabel id="genderLabel">Gender</InputLabel>
            <Select
              labelId="genderLabel"
              name="gender"
              value={values.gender}
              label="Gender"
              onChange={handleChange}
            >
              <MenuItem value="Female">Female</MenuItem>
              <MenuItem value="Male">Male</MenuItem>
            </Select>
          </FormControl>
          <TextField
            fullWidth
            label="Phone *"
            margin="normal"
            name="phone"
            onChange={handleChange}
            type="tel"
            value={values.phone}
            variant="outlined"
          />
          <CardHeader title="Billing address" />
          <Divider />
          <TextField
            fullWidth
            label="Street address *"
            margin="normal"
            name="street"
            onChange={handleChange}
            type="text"
            value={values.street}
            variant="outlined"
          />
          <Box sx={{ display: 'flex' }}>
            <Box sx={{ flex: 1, pr: 1 }}>
              <TextField
                fullWidth
                label="City *"
                margin="normal"
                name="city"
                onChange={handleChange}
                type="text"
                value={values.city}
                variant="outlined"
              />
            </Box>
            <Box sx={{ flex: 1, pl: 1 }}>
              <CustomerSelectState
                handleChange={handleChange}
                value={values.state}
              />
            </Box>
          </Box>
          <Box sx={{ display: 'flex' }}>
            <Box sx={{ flex: 1, pr: 1 }}>
              <TextField
                fullWidth
                label="Postal code *"
                margin="normal"
                name="postalCode"
                onChange={handleChange}
                type="text"
                value={values.postalCode}
                variant="outlined"
              />
            </Box>
            <Box sx={{ flex: 1, pl: 1 }}>
              <CustomerSelectCountry
                value={values.country}
                handleChange={handleChange}
                mt={2}
                mb={1}
                blocked={true}
              />
            </Box>
          </Box>
          <CustomerAlert
            status={alert.status}
            message={alert.message}
            onClose={() => setAlert({ status: '', message: '' })}
          />
        </CardContent>
        <Divider />
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
          <Button color="primary" variant="contained" onClick={submit}>
            Save
          </Button>
        </Box>
      </Card>
    </form>
  );
};

CustomerFormEdit.propTypes = {
  id: PropTypes.string.isRequired
};

export default CustomerFormEdit;
