import React, { useState, useEffect, } from 'react';
import { Helmet } from 'react-helmet';
import {
  Box,
  Container,
  Button,
  Alert,
  CardHeader,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Breadcrumbs,
  Typography,
} from '@material-ui/core';
import ReactQuill from 'react-quill';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import {
    useGet,
    useHttpDelete,
    usePut,
    usePost
} from '../../request';
import 'react-quill/dist/quill.snow.css';
import '../../styles/All.css';

const modules = {
  toolbar: [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ['bold', 'italic', 'underline', 'strike'],
    ['link', 'image'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    ['clean'],
  ],
};

const DocumentAdd = () => {
    const [context, setContext] = useState('');
    const [docs_key, setDocsKey] = useState('');
    const postM = usePost();
    const navigate = useNavigate();
    const put = usePut();
    const [alert, setAlert] = useState({
        txt: '',
        isVisible: false,
        type: 'error'
    });
    const [submitDisabled, setSubmitDisabled] = useState(false);

    const handleChange = (event) => {
      setDocsKey(event.target.value)
    };

    const handleQuillChange = (value) => {
      setContext(value);
    }

    const clearForm = () => {
        setDocsKey('');
        setContext('');
    };

    const showAlert = (type, text) => {
        setAlert({
          txt: text,
          type,
          isVisible: true
        });

        setTimeout(() => {
          setAlert({
            txt: text,
            type,
            isVisible: false
          });

          setSubmitDisabled(false);
        }, 1400);
    };

    const submit = async () => {
        const document = {
          docs_key,
          context
        };

        if (document.docs_key === '') {
            showAlert('error', 'Specify document type');
        }
        if (document.context === '') {
            showAlert('error', 'Document must not be empty');
        }
        if (document.context === '<p><br></p>') {
          showAlert('error', 'Document must not be empty');
      }

        if (
          document.docs_key !== ''
          && document.context !== ''
          && document.context !== '<p><br></p>'
        ) {
            put('admin/docs', document)
            .then((resp) => {
              if (resp.result === 'success') {
                showAlert('success', 'Document added');
                clearForm();
              } else {
                showAlert('error', 'Error');
              }
            })
            .catch((err) => {
              console.log(err);
              showAlert('error', 'Server Error');
            })
            .finally(() => {});
        }
    }

    return (
        <>
            <Helmet>
              <title>Create Document</title>
            </Helmet>
            <Box className="headerWrapper">
                <Box className="headerTitle" sx={{ width: '92%', display: 'flex', justifyContent: 'space-between' }}>
                <Box sx={{ py: 2 }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', pb: 1 }}>
                    <Box style={{ marginTop: 3, marginRight: 10 }}>
                        <Button startIcon={<ChevronLeft />} onClick={() => navigate(-1)}>
                            Back
                        </Button>
                    </Box>
                    <Typography color="textPrimary" variant="h4" component="h1">
                      Create Document
                    </Typography>
                  </Box>
                  <Breadcrumbs aria-label="breadcrumb" style={{ fontSize: 12 }}>
                    <RouterLink underline="hover" color="inherit" to="/app/documents-list">
                      Documents List
                    </RouterLink>
                      <p>Create Document</p>
                  </Breadcrumbs>
                </Box>
                </Box>
            </Box>
            <Alert
              severity={alert.type}
              style={{ display: alert.isVisible ? 'flex' : 'none' }}
            >
              {alert.txt}
            </Alert>
            <Box
              sx={{
                width: '90%',
                height: '85%',
                marginTop: '2%',
                marginLeft: '2%',
                backgroundColor: 'white',
                borderRadius: '5px',
                border: '1px solid #6b778c83',
              }}
            >
              <PerfectScrollbar>
              <Container maxWidth={false} style={{ height: '70%' }}>
                <FormControl fullWidth sx={{ mt: 2, mb: 1 }}>
                    <InputLabel id="type">Type Document</InputLabel>
                    <Select
                      labelId="type"
                      id="type"
                      label="Type Document"
                      value={docs_key}
                      onChange={handleChange}
                    >
                        <MenuItem value="">Not chosen</MenuItem>
                        <MenuItem value="1">Membership_Agreement</MenuItem>
                        <MenuItem value="2">Privacy_Policy</MenuItem>
                        <MenuItem value="3">Terms_and_conditions</MenuItem>
                    </Select>
                </FormControl>
                <ReactQuill
                  theme="snow"
                  style={{ height: '85%', marginTop: '15px', }}
                  modules={modules}
                  value={context}
                  onChange={handleQuillChange}
                />
                <Box sx={{
                  position: 'sticky',
                  bottom: 10,
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginTop: '55px',
                  marginLeft: '50px',
                }}
                >
                  <Button
                    style={{ height: '50px', width: '190px' }}
                    onClick={submit}
                    color="primary"
                    variant="contained"
                  >
                      Create Document
                  </Button>
                </Box>
              </Container>
              </PerfectScrollbar>
            </Box>
        </>
    )
}

export default DocumentAdd;
