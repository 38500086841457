import { Box, Tab, Tabs } from '@material-ui/core';
import { useState } from 'react';
import CustomerList from '../components/customer/CustomerList';
import ExpiredCustomers from '../components/customer/ExpiredPaymentsCustomers';
import FreeCustomers from '../components/customer/FreeCustomers';

const tabsList = [
  {
    id: 0,
    label: 'All customers',
    wrapped: false,
    component: CustomerList
  },
  {
    id: 1,
    label: 'Customers with expired payments',
    wrapped: true,
    component: ExpiredCustomers
  },
  {
    id: 2,
    label: 'Free customers',
    wrapped: false,
    component: FreeCustomers
  }
];

const Customers = () => {
  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (_, newValue) => {
    setTabValue(newValue);
  };
  const CurrentTab = tabsList.find(({ id }) => id === tabValue)?.component;

  return (
    <Box
      id={`customer-tabpanel-${tabValue}`}
      aria-labelledby={`customer-tab-${tabValue}`}
    >
      {CurrentTab && (
        <CurrentTab>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="customer tabs"
            sx={{ mx: 2 }}
          >
            {tabsList.map(({ id, label, wrapped }) => (
              <Tab
                label={label}
                id={`customer-tab-${id}`}
                aria-controls={`customer-tabpanel-${id}`}
                wrapped={wrapped}
                key={id}
              />
            ))}
          </Tabs>
        </CurrentTab>
      )}
    </Box>
  );
};

export default Customers;

