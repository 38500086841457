import { useState } from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    TextField,
    CardContent,
    CardHeader,
    Card,
    Divider,
    Button
} from '@material-ui/core';
import {
    usePost
} from '../../request';
import CustomerAlert from './CustomerAlert';

const CustomerSetPassForm = (props) => {
    const { id } = props;
    const [values, setValues] = useState({
      password: '',
      confirm: ''
    });
    const [alert, setAlert] = useState({
      status: '',
      message: ''
    });
    const post = usePost();

    const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
    };

    const submit = () => {
      const requestData = {
        user_password: values.password,
        user_repeat_password: values.confirm
      };

      // console.log(requestData);

      post(`change-pass/${id}`, requestData)
      .then((response) => {
        setAlert({
          status: response.result,
          message: response.message
        });
      })
      .catch((e) => console.log(e));
    };

    return (
      <form>
        <Box sx={{ pt: 3 }}>
            <Card>
              <CardHeader
                title="Password"
                subheader="Set password"
              />
              <Divider />
              <CardContent>
                  <TextField
                    fullWidth
                    label="Password"
                    margin="normal"
                    name="password"
                    onChange={handleChange}
                    type="password"
                    value={values.password}
                    variant="outlined"
                  />
                  <TextField
                    fullWidth
                    label="Confirm password"
                    margin="normal"
                    name="confirm"
                    onChange={handleChange}
                    type="password"
                    value={values.confirm}
                    variant="outlined"
                  />
                  <CustomerAlert status={alert.status} message={alert.message} onClose={() => setAlert({ status: '', message: '' })} />
              </CardContent>
              <Divider />
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={submit}
                  >
                      Save
                  </Button>
              </Box>
            </Card>
        </Box>
      </form>
    );
};

CustomerSetPassForm.propTypes = {
  id: PropTypes.string.isRequired
};

export default CustomerSetPassForm;
