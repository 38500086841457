import { Helmet } from 'react-helmet';
import {
    Box,
    Container
} from '@material-ui/core';
import BrokerFormCreate from '../components/broker/BrokerFormCreate';

const BrokerAdd = () => (
    <>
        <Helmet>
            <title>Create new broker | Material Kit</title>
        </Helmet>
        <Box sx={{ backgroundColor: 'background.default', minHeight: '100%' }}>
            <Container maxWidth={false}>
                <BrokerFormCreate />
            </Container>
        </Box>
    </>
);

export default BrokerAdd;
