import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import {
    Box,
    Container,
    Button,
    Breadcrumbs,
    Typography,
    CircularProgress
} from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import ServiceListResults from '../components/service/ServiceListResults';
import {
    useGet,
    useHttpDelete
} from '../request';
import { useConfirm } from '../components/confirm';
import { useAlert } from '../components/alert';

const ServiceList = () => {
    const [serviceList, setServiceList] = useState(null);
    const get = useGet();
    const httpDelete = useHttpDelete();
    const navigate = useNavigate();
    const alert = useAlert();
    const confirm = useConfirm();

    const loadServices = () => {
        get('admin/services')
        .then((response) => {
            if (response.result === 'success') {
                setServiceList(response.services);
            }
        })
        .catch((e) => console.log(e));
    };

    useEffect(() => {
        loadServices();
    }, []);

    const deleteService = (id) => {
        confirm({
            title: 'Deleting',
            content: 'Are you sure you want to delete the service?',
            isDeleting: true,
            onConfirm: () => {
                httpDelete(`admin/services/${id}`)
                .then((response) => {
                    if (response.result === 'error') {
                        alert({
                            status: 'error',
                            message: response.message
                        });
                        return;
                    }
                    loadServices()
                })
                .catch((e) => console.log(e));
            }
        });
    };

    if (!serviceList) {
        return (
            <Box sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: 100
            }}
            >
                <CircularProgress />
            </Box>
        );
    }

    return (
        <>
            <Helmet>
                <title>Networks | Material Kit</title>
            </Helmet>
            <Box sx={{ backgroundColor: 'background.default', minHeight: '100%' }}>
                <Container maxWidth={false}>
                    <Box sx={{ py: 2 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', pb: 1 }}>
                            <Box style={{ marginTop: 3, marginRight: 10 }}>
                                <Button startIcon={<ChevronLeft />} onClick={() => navigate(-1)}>
                                    Back
                                </Button>
                            </Box>
                            <Typography color="textPrimary" variant="h4" component="h1">
                                Networks
                            </Typography>
                            <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: 'auto' }}>
                                <RouterLink to="/app/service/add">
                                    <Button color="primary" variant="contained">
                                        Add network
                                    </Button>
                                </RouterLink>
                            </Box>
                        </Box>
                        <Breadcrumbs aria-label="breadcrumb" style={{ fontSize: 12 }}>
                            <p>Networks</p>
                        </Breadcrumbs>
                    </Box>
                    <Box sx={{ paddingBottom: 2 }}>
                        <ServiceListResults services={serviceList} deleteService={deleteService} />
                    </Box>
                </Container>
            </Box>
        </>
    );
};

export default ServiceList;
