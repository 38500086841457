/* eslint-disable */
import React, { useState , useEffect} from 'react';
import { Helmet } from 'react-helmet';
import {
    Box,
    Container,
    Typography,
    Breadcrumbs,
    Button,
    CircularProgress
} from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import LogTable from '../components/LogTable';
import {useGet, usePost} from "../request";

const LogAppPage = function () {
    const post = usePost(),
        get = useGet();

    const [isShowLoader, setIsShowLoader] = useState(false);
    const navigate = useNavigate();

    let [logs, setLogs] = useState([]);

    const loadLog = () => {
        /*setIsShowLoader(true);
        get('admin/get-logs')
            .then((response) => {
                setIsShowLoader(false);
                if (response.result === 'success') {
                    setLogs(response.logs);
                }
            })
            .catch((e) => console.log(e));*/
    };

    const clearLogs = () => {
        /*post('admin/clear-logs')
            .then((response) => {
                if (response.result === 'success') {
                    setLogs([]);
                }
            })
            .catch((e) => console.log(e));*/
    };

    useEffect(() => {
        loadLog();
    }, []);

    return (
        <>
            <Helmet>
                <title>Log App Page | Material Kit</title>
            </Helmet>
            <Box sx={{ backgroundColor: 'background.default', minHeight: '100%' }}>
                <Container maxWidth={false}>
                    <Box sx={{ py: 2 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', pb: 1 }}>
                            <Box style={{ marginTop: 3, marginRight: 10 }}>
                                <Button startIcon={<ChevronLeft />} onClick={() => navigate(-1)}>
                                    Back
                                </Button>
                            </Box>
                            <Typography color="textPrimary" variant="h4" component="h1">
                                Log App
                            </Typography>
                            <Box sx={{ display: 'flex', marginLeft: 'auto' }}>
                                <Button color="primary" variant="contained" onClick={clearLogs}>
                                    Clear
                                </Button>
                            </Box>
                        </Box>
                        <Breadcrumbs aria-label="breadcrumb" style={{ fontSize: 12 }}>
                            <p>Log App</p>
                        </Breadcrumbs>
                    </Box>
                    { isShowLoader ? (
                        <Box sx={{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          bottom: 0,
                          right: 0,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          zIndex: 5
                          }}
                        >
                            <CircularProgress />
                        </Box>
                    ) : (
                        <LogTable logs={logs} />
                    )}
                </Container>
            </Box>
        </>
    );
};

export default LogAppPage;
