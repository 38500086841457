import { useState, useRef } from 'react';
import { Helmet } from 'react-helmet';
import {
    Box,
    Container,
    Card,
    CardHeader,
    Divider,
    CardContent,
    Typography,
    Button,
    Breadcrumbs,
    CircularProgress
} from '@material-ui/core';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import {
    usePost
} from '../request';
import CustomerAlert from '../components/customer/CustomerAlert';

const CustomerImport = () => {
    const [file, setFile] = useState(null);
    const post = usePost();
    const [isLoadingImport, setIsLoadingImport] = useState(false);
    const [alert, setAlert] = useState({
        status: '',
        message: ''
    });
    const navigate = useNavigate();
    const inputImport = useRef();

    const handleFile = (event) => {
        setFile(event.target.files[0]);
    }

    const handleImport = () => {
        setIsLoadingImport(true);
        const formData = new FormData();
        formData.append('file', file);

        const headers = {
            'Content-Type': 'multipart/form-data'
        };

        post('admin/bulk-upload', formData, { headers })
        .then((response) => {
            setIsLoadingImport(false);
            setFile(null);
            inputImport.current.value = '';
            if (response.result !== 'success') {
                setAlert({
                  status: 'error',
                  message: response.message
                });
                return;
            }
            let status = response.not_added ? 'info' : 'success';
            status = response.added ? status : 'error';
            setAlert({
              status,
              message: (response.added ? `Success added ${response.added} users! ` : '') + (response.not_added ? `Not added ${response.not_added} users` : '')
            });
        })
        .catch((e) => {
            console.log(e);
            setIsLoadingImport(false);
        });
    }

    if (isLoadingImport) {
        return (
            <Box sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: 100
            }}
            >
                <CircularProgress />
            </Box>
        );
    }

    return (
        <>
            <Helmet>
                <title>Customers import | Material Kit</title>
            </Helmet>
            <Box sx={{ backgroundColor: 'background.default', minHeight: '100%' }}>
                <Container maxWidth={false}>
                    <Box sx={{ py: 2 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', pb: 1 }}>
                            <Box style={{ marginTop: 3, marginRight: 10 }}>
                                <Button startIcon={<ChevronLeft />} onClick={() => navigate(-1)}>
                                    Back
                                </Button>
                            </Box>
                        </Box>
                        <Breadcrumbs aria-label="breadcrumb" style={{ fontSize: 12 }}>
                            <RouterLink underline="hover" color="inherit" to="/app/customers">
                                Customers
                            </RouterLink>
                            <p>Import</p>
                        </Breadcrumbs>
                    </Box>
                    <Card sx={{ mb: 2 }}>
                        <CardHeader title="Import" titleTypographyProps={{ variant: 'h5' }} component="h2" />
                        <Divider />
                        <CardContent sx={{ position: 'relative' }}>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Button
                                  variant="contained"
                                  component="label"
                                  sx={{ marginRight: 2, backgroundColor: '#646db5' }}
                                >
                                  Select File
                                  <input
                                    ref={inputImport}
                                    type="file"
                                    onChange={handleFile}
                                    hidden
                                  />
                                </Button>
                                { file && (
                                    <Typography color="textPrimary" variant="body1">
                                        {file.name}
                                    </Typography>
                                )}
                            </Box>
                            { (alert.status || alert.message)
                            ? (
                                <CustomerAlert status={alert.status} message={alert.message} onClose={() => setAlert({ status: '', message: '' })} />
                            )
                            : undefined}
                        </CardContent>
                        { (file && !isLoadingImport) && (
                            <Box>
                                <Divider />
                                <Box sx={{ p: 2, display: 'flex', justifyContent: 'flex-end' }}>
                                    <Button
                                      color="primary"
                                      variant="contained"
                                      onClick={handleImport}
                                    >
                                        Import
                                    </Button>
                                </Box>
                            </Box>
                        )}
                    </Card>
                </Container>
            </Box>
        </>
    );
};

export default CustomerImport;
