/* eslint-disable */

import { useState, useEffect, useLayoutEffect } from 'react';
import { Doughnut } from 'react-chartjs-2';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  colors,
  useTheme
} from '@material-ui/core';
import LaptopMacIcon from '@material-ui/icons/LaptopMac';
import PhoneIcon from '@material-ui/icons/Phone';
import TabletIcon from '@material-ui/icons/Tablet';
import { useGet, usePost } from '../../request';

const TrafficByDevice = ({ userStats, sx }) => {
  const theme = useTheme();
  const get = useGet();

  const [data, setData] = useState({
    datasets: [
      {
        data: [0, 0, 0],
        backgroundColor: [
          colors.indigo[500],
          colors.red[600],
          colors.orange[600]
        ],
        borderWidth: 8,
        borderColor: colors.common.white,
        hoverBorderColor: colors.common.white
      }
    ],
    labels: ['Users', 'New', 'Active']
  });

  const options = {
    animation: false,
    cutoutPercentage: 80,
    layout: { padding: 0 },
    legend: {
      display: false
    },
    maintainAspectRatio: false,
    responsive: true,
    tooltips: {
      backgroundColor: theme.palette.background.paper,
      bodyFontColor: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      borderWidth: 1,
      enabled: true,
      footerFontColor: theme.palette.text.secondary,
      intersect: false,
      mode: 'index',
      titleFontColor: theme.palette.text.primary
    }
  };

  const [devices, setDevices] = useState([
    {
      title: 'Users',
      value: 63,
      icon: LaptopMacIcon,
      color: colors.indigo[500]
    },
    {
      title: 'New',
      value: 15,
      icon: TabletIcon,
      color: colors.red[600]
    },
    {
      title: 'Active',
      value: 23,
      icon: PhoneIcon,
      color: colors.orange[600]
    }
  ]);

  /*
  let devices = [
    {
      title: 'Users',
      value: 63,
      icon: LaptopMacIcon,
      color: colors.indigo[500]
    },
    {
      title: 'New',
      value: 15,
      icon: TabletIcon,
      color: colors.red[600]
    },
    {
      title: 'Active',
      value: 23,
      icon: PhoneIcon,
      color: colors.orange[600]
    }
  ];
  */

  useLayoutEffect(() => {
    if (!userStats) return;
    let set = devices;
    let totalUsers = userStats.total;
    set[0].value =
      (userStats.total -
        userStats.newUserCount -
        userStats.activePaymentCount) *
      100;
    set[0].value = Math.floor(set[0].value / userStats.total);

    set[1].value = userStats.newUserCount * 100;
    set[1].value = Math.floor(set[1].value / userStats.total);

    set[2].value = userStats.activePaymentCount * 100;
    set[2].value = Math.floor(set[2].value / userStats.total);

    setDevices([
      {
        ...devices[0]
      },
      {
        ...devices[1]
      },
      {
        ...devices[2]
      }
    ]);

    setData({
      datasets: [
        {
          data: [set[0].value, set[1].value, set[2].value],
          backgroundColor: [
            colors.indigo[500],
            colors.red[600],
            colors.orange[600]
          ],
          borderWidth: 8,
          borderColor: colors.common.white,
          hoverBorderColor: colors.common.white
        }
      ],
      labels: ['Users', 'New', 'Active']
    });
  }, [userStats]);

  return (
    <Card style={{height: '100%'}}>
      <CardHeader title="Traffic by Device" />
      <Divider />
      <CardContent>
        <Box
          sx={{
            height: 300,
            position: 'relative'
          }}
        >
          <Doughnut data={data} options={options} />
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            pt: 2
          }}
        >
          {devices.map(({ color, icon: Icon, title, value }) => (
            <Box
              key={title}
              sx={{
                p: 1,
                textAlign: 'center'
              }}
            >
              <Icon color="action" />
              <Typography color="textPrimary" variant="body1">
                {title}
              </Typography>
              <Typography style={{ color }} variant="h2">
                {value}%
              </Typography>
            </Box>
          ))}
        </Box>
      </CardContent>
    </Card>
  );
};

export default TrafficByDevice;
