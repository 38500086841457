import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
    Box,
    Container,
    Button,
    Typography,
    Card,
    Table,
    TableBody,
    TableCell,
    TableHead,
    Breadcrumbs,
    TableRow,
    CircularProgress
} from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import {
    useGet,
} from '../request';

const EmailRegistration = () => {
    const [emailList, setEmailList] = useState(null);
    const navigate = useNavigate();
    const get = useGet();

    const loadEmails = () => {
        get('users/email')
        .then((response) => {
            if (response.result === 'success') {
                setEmailList(response.data);
            }
        })
        .catch((e) => console.log(e));
    };

    useEffect(() => {
        loadEmails();
    }, []);

    if (!emailList) {
        return (
            <Box sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: 100
            }}
            >
                <CircularProgress />
            </Box>
        );
    }

    return (
        <>
            <Helmet>
                <title>Email registration | Material Kit</title>
            </Helmet>
            <Box sx={{ backgroundColor: 'background.default', minHeight: '100%' }}>
                <Container maxWidth={false}>
                    <Box sx={{ py: 2 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', pb: 1 }}>
                            <Box style={{ marginTop: 3, marginRight: 10 }}>
                                <Button startIcon={<ChevronLeft />} onClick={() => navigate(-1)}>
                                    Back
                                </Button>
                            </Box>
                            <Typography color="textPrimary" variant="h4" component="h1">
                                Email registration
                            </Typography>
                        </Box>
                        <Breadcrumbs aria-label="breadcrumb" style={{ fontSize: 12 }}>
                            <p>Email registration</p>
                        </Breadcrumbs>
                    </Box>
                    <Card>
                        <PerfectScrollbar>
                            <Box sx={{ minWidth: 1050 }}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>
                                                Name
                                            </TableCell>
                                            <TableCell>
                                                Date create
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {emailList.map((item) => (
                                            <TableRow key={item.id}>
                                                <TableCell>
                                                    {item.email}
                                                </TableCell>
                                                <TableCell>
                                                    {item.date_create}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Box>
                        </PerfectScrollbar>
                    </Card>
                </Container>
            </Box>
        </>
    );
};

export default EmailRegistration;
