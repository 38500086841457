import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import {
    Box,
    Container,
    Breadcrumbs,
    Button,
    CircularProgress
} from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import { Link as RouterLink, useParams, useNavigate } from 'react-router-dom';
import CustomerFormEdit from '../components/customer/CustomerFormEdit';
import CustomerSetPassForm from '../components/customer/CustomerSetPassForm';
import { useGet } from '../request';

const CustomerEdit = () => {
    const { id } = useParams();
    const [customer, setCustomer] = useState(null);
    const navigate = useNavigate();
    const get = useGet();

    useEffect(() => {
        get(`get-user/${id}`)
        .then((response) => {
            if (response.result !== 'success') {
                return;
            }
            setCustomer(response.user);
        })
        .catch((e) => console.log(e));
    }, []);

    if (!customer) {
        return (
            <Box sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: 100
            }}
            >
                <CircularProgress />
            </Box>
        );
    }

    return (
        <>
            <Helmet>
                <title>Edit customer | Material Kit</title>
            </Helmet>
            <Box sx={{ backgroundColor: 'background.default', minHeight: '100%' }}>
                <Container maxWidth={false}>
                    <Box sx={{ py: 2 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', pb: 1 }}>
                            <Box style={{ marginTop: 3, marginRight: 10 }}>
                                <Button startIcon={<ChevronLeft />} onClick={() => navigate(-1)}>
                                    Back
                                </Button>
                            </Box>
                        </Box>
                        <Breadcrumbs aria-label="breadcrumb" style={{ fontSize: 12 }}>
                            <RouterLink underline="hover" color="inherit" to="/app/customers">
                                Customers
                            </RouterLink>
                            <RouterLink underline="hover" color="inherit" to={`/app/customer/${customer.id}`}>
                                {`${customer.user_first_name} ${customer.user_last_name}`}
                            </RouterLink>
                            <p>Edit</p>
                        </Breadcrumbs>
                    </Box>
                    <Box>
                        <CustomerFormEdit id={String(id)} />
                    </Box>
                    <Box sx={{ pt: 3 }}>
                        <CustomerSetPassForm id={String(id)} />
                    </Box>
                </Container>
            </Box>
        </>
    );
};

export default CustomerEdit;
