/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import {
  Box,
  Container,
  Typography,
  Breadcrumbs,
  Button,
  CircularProgress,
  TextField,
  Divider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Card,
  CardContent,
  Checkbox,
  Tooltip
} from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import { useGet, usePut } from '../request';
import { useAlert } from '../components/alert';

const SubscriptionEdit = function () {
  const { id } = useParams();
  const [values, setValues] = useState({
    name: '',
    amount: '',
    duration: '',
    stripe_plan_id: '',
    group_id: ''
  });
  const [subscription, setSubscription] = useState({});
  const [products, setProducts] = useState([]);
  const [groups, setGroups] = useState([]);
  const [isShowLoader, setIsShowLoader] = useState(false);
  const [isActivedFamaly, setIsActivedFamaly] = useState(false);
  const alert = useAlert();
  const navigate = useNavigate();
  const put = usePut();
  const get = useGet();

  useEffect(() => {
    (async () => {
      setIsShowLoader(true);
      await loadSubscription();
      await loadProducts();
      await loadGroups();
      setIsShowLoader(false);
    })();
  }, []);

  useEffect(() => {
    if (subscription) {
      setValues((prev) => ({
        ...prev,
        ...subscription
      }));
    }
  }, [subscription, groups]);

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const loadSubscription = async () => {
    const response = await get(`admin/subscriptions/${id}`);
    if (response.result === 'success') {
      setSubscription({
        ...response?.data,
        group_id:
          response?.data?.group_id === 0 ? null : response?.data?.group_id
      });

      if ('family_members_access' in response.data) {
        setIsActivedFamaly(() => response.data.family_members_access === 1);
      }
    }
  };

  const loadProducts = async () => {
    const response = await get(`api/strapi/get-products-to-select/${id}`);
    if (response.result === 'success') {
      setProducts(response.products || []);
    }
  };

  const loadGroups = async () => {
    const response = await get('admin/group/get-for-stripe');
    if (response.result === 'success') {
      setGroups(response.groups);
    }
  };

  const submit = async () => {
    if (!values.name) {
      alert({
        status: 'error',
        message: 'Name is required'
      });
      return;
    }

    setIsShowLoader(true);
    const requestData = {
      name: values.name,
      its_sub_products: values.its_sub_products,
      duration: values.duration,
      stripe_plan_id: values.stripe_plan_id,
      group_id:
        values.group_id === '' || !values.group_id ? 0 : values.group_id,
      description: values.description,
      family_members_access: isActivedFamaly ? 1 : 0
    };

    put(`admin/subscriptions/${id}`, requestData)
      .then((response) => {
        console.log(response);
        if (response.result === 'success') {
          alert({
            status: 'success',
            message: 'Successfully'
          });
        } else {
          alert({
            status: 'error',
            message: response?.message
          });
        }
      })
      .catch((e) => {
        console.log(e);
        alert({
          status: 'error',
          message: e?.response?.message
        });
      })
      .finally(() => {
        setIsShowLoader(false);
      });
  };

  return (
    <>
      <Helmet>
        <title>Subscription Edit | Material Kit</title>
      </Helmet>
      <Box sx={{ backgroundColor: 'background.default', minHeight: '100%' }}>
        <Container maxWidth={false}>
          {isShowLoader ? (
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: 5
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <>
              <Box sx={{ py: 2 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', pb: 1 }}>
                  <Box style={{ marginTop: 3, marginRight: 10 }}>
                    <Button
                      startIcon={<ChevronLeft />}
                      onClick={() => navigate(-1)}
                    >
                      Back
                    </Button>
                  </Box>
                  <Typography color="textPrimary" variant="h4" component="h1">
                    Edit {subscription?.name}
                  </Typography>
                </Box>
                <Breadcrumbs aria-label="breadcrumb" style={{ fontSize: 12 }}>
                  <RouterLink
                    underline="hover"
                    color="inherit"
                    to="/app/subscriptions"
                  >
                    Subscriptions
                  </RouterLink>
                  <p>Edit {subscription?.name}</p>
                </Breadcrumbs>
              </Box>
              <Card>
                <CardContent sx={{ position: 'relative' }}>
                  <TextField
                    fullWidth
                    label="Name"
                    margin="normal"
                    name="name"
                    onChange={handleChange}
                    type="text"
                    value={values.name}
                    variant="outlined"
                  />

                  <TextField
                    fullWidth
                    label="Description"
                    margin="normal"
                    name="description"
                    onChange={handleChange}
                    type="text"
                    value={values.description}
                    variant="outlined"
                    multiline
                    rows={3}
                    rowsMax={8}
                  />

                  <div style={{ display: 'flex', columnGap: 25 }}>
                    <FormControl fullWidth sx={{ mt: 2, mb: 1 }}>
                      <InputLabel id="itsSubProductsLabel">
                        VurvProduct Type
                      </InputLabel>
                      <Select
                        labelId="itsSubProductsLabel"
                        name="its_sub_products"
                        value={values.its_sub_products}
                        label="VurvProduct Type"
                        onChange={handleChange}
                      >
                        <MenuItem value={0}>Base subscription</MenuItem>
                        <MenuItem value={1}>Sub Products</MenuItem>
                      </Select>
                    </FormControl>

                    <TextField
                      fullWidth
                      label="Duration (days)"
                      margin="normal"
                      name="duration"
                      onChange={handleChange}
                      type="text"
                      value={values.duration}
                      variant="outlined"
                    />
                  </div>
                  <FormControl fullWidth sx={{ mt: 2, mb: 1 }}>
                    <InputLabel id="stripePlanIdLabel">Product</InputLabel>
                    <Select
                      labelId="stripePlanIdLabel"
                      name="stripe_plan_id"
                      value={values.stripe_plan_id}
                      label="Product"
                      onChange={handleChange}
                    >
                      <MenuItem value="">No select</MenuItem>
                      {products.map(({ name, plan_id }) => (
                        <MenuItem value={plan_id}>{name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl fullWidth sx={{ mt: 2, mb: 1 }}>
                    <InputLabel id="stripePlanIdLabel">Group</InputLabel>
                    <Select
                      labelId="group_id"
                      name="group_id"
                      value={values.group_id}
                      label="Group"
                      onChange={handleChange}
                    >
                      <MenuItem value={''}>No select</MenuItem>
                      {groups.map(({ name, id }) => (
                        <MenuItem value={id}>{name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <Typography style={{ textAlign: 'center' }}>
                    If you set this options, all users who purchase this
                    subscription is get to choiced group
                  </Typography>

                  <Typography
                    sx={{
                      pt: 2,
                      pb: 2,
                      display: 'inline-block',
                      cursor: 'help'
                    }}
                    variant="h2"
                  >
                    <p>Addition options</p>
                  </Typography>

                  <Box
                    sx={{
                      alignItems: 'center',
                      display: 'flex',
                      ml: -1
                    }}
                  >
                    <label
                      style={{
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center'
                      }}
                    >
                      <Checkbox
                        checked={isActivedFamaly}
                        onChange={() => setIsActivedFamaly((prev) => !prev)}
                      />
                      <p
                        style={
                          {
                            // minWidth: 600
                          }
                        }
                      >
                        {isActivedFamaly
                          ? 'Allow to add Family Members'
                          : 'Do not allow adding family members'}
                      </p>
                      <Tooltip
                        title="Default customer don't see this option in app"
                        placement="top"
                      >
                        <strong
                          style={{
                            paddingLeft: 5,
                            color: '#5664D2',
                            opacity: 0.9,
                            cursor: 'help'
                          }}
                        >
                          (?)
                        </strong>
                      </Tooltip>
                    </label>
                  </Box>
                </CardContent>
                <Divider />
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
                  <Button color="primary" variant="contained" onClick={submit}>
                    Save
                  </Button>
                </Box>
              </Card>
            </>
          )}
        </Container>
      </Box>
    </>
  );
};

export default SubscriptionEdit;
