import { uploadURL } from '../enviroment';

const getAvatar = (user) => {
    if (!user.user_avatar_url) {
        return null;
    }
    if (user.user_avatar_url.charAt(0) === '/') {
        return `${uploadURL}${user.user_avatar_url}`;
    }
    return user.user_avatar_url;
}

export default getAvatar;
