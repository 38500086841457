import { useState } from 'react';
import {
  Box,
  TextField,
  CardContent,
  CardHeader,
  Card,
  Divider,
  Breadcrumbs,
  CircularProgress,
  Button
} from '@material-ui/core';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import {
  usePost
} from '../../request';
import ServiceAlert from './ServiceAlert';

const ServiceFormCreate = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [name, setName] = useState('');
    const [alert, setAlert] = useState({
      status: '',
      message: ''
    });
    const navigate = useNavigate();
    const post = usePost();

    const handleName = (event) => {
        setName(event.target.value);
    };

    const submit = () => {
      setIsLoading(true);
      const requestData = {
        name,
      };

      // console.log(requestData);

      post('admin/services', requestData)
      .then((response) => {
        setIsLoading(false);
        if (response.result === 'success') {
          setAlert({
            status: response.result,
            message: 'Successfully'
          });
          return
        }
        setAlert({
          status: response.result,
          message: response.message
        });
      })
      .catch((e) => {
        setIsLoading(false);
        console.log(e)
      });
    };

    if (isLoading) {
        return (
            <Box sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: 100
            }}
            >
                <CircularProgress />
            </Box>
        );
    }

    return (
      <>
        <Box sx={{ py: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', pb: 1 }}>
                <Box style={{ marginTop: 3, marginRight: 10 }}>
                    <Button startIcon={<ChevronLeft />} onClick={() => navigate(-1)}>
                        Back
                    </Button>
                </Box>
            </Box>
            <Breadcrumbs aria-label="breadcrumb" style={{ fontSize: 12 }}>
                <RouterLink underline="hover" color="inherit" to="/app/services">
                    Networks
                </RouterLink>
                <p>Create</p>
            </Breadcrumbs>
        </Box>
        <form>
          <Card>
            <CardHeader
              title="Create new network"
            />
            <Divider />
            <CardContent>
                <TextField
                  fullWidth
                  label="Name"
                  margin="normal"
                  name="name"
                  onChange={handleName}
                  type="text"
                  value={name}
                  variant="outlined"
                />
                <ServiceAlert status={alert.status} message={alert.message} onClose={() => setAlert({ status: '', message: '' })} />
            </CardContent>
            <Divider />
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={submit}
                >
                    Create
                </Button>
            </Box>
          </Card>
        </form>
      </>
    );
};

export default ServiceFormCreate;
