import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core';
import PropTypes from 'prop-types';

const GroupImportList = ({ operationLog }) => {
  return (
    <Table cellPadding={6}>
      <TableHead>
        <TableRow>
          <TableCell sx={{ width: 80 }}>ID</TableCell>
          <TableCell>Status</TableCell>
          <TableCell />
        </TableRow>
      </TableHead>
      <TableBody>
        {operationLog.map((log, index) => (
          <TableRow hover key={`${log}${Date.now()}${index}`}>
            <TableCell sx={{ width: 80 }}>{index + 1}</TableCell>
            <TableCell>{log}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

GroupImportList.propTypes = {
  operationLog: PropTypes.array
};

export default GroupImportList;

