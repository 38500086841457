import { useState } from 'react';
import { Helmet } from 'react-helmet';
import {
    Box,
    Container,
    Card,
    CardHeader,
    Divider,
    CardContent,
    Typography,
    Button,
    FormControl,
    InputLabel,
    Select,
    CircularProgress,
    Breadcrumbs,
    MenuItem
} from '@material-ui/core';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import FileDownload from 'js-file-download';
import { useGet } from '../request';
import { uploadURL } from '../enviroment';
import CustomerAlert from '../components/customer/CustomerAlert';

const CustomerExport = () => {
    const [statusVurvID, setStatusVurvID] = useState('active');
    const [isLoading, setIsLoading] = useState(false);
    const [alert, setAlert] = useState({
        status: '',
        message: ''
    });
    const navigate = useNavigate();
    const get = useGet();

    const handleStatusVurvID = (event) => {
        setStatusVurvID(event.target.value);
    };

    const handleExport = () => {
        setIsLoading(true);
        get(`admin/export-users/?sort=${statusVurvID}`, { responseType: 'blob' })
        .then((response) => {
            setIsLoading(false);
            setAlert({
                status: 'success',
                message: 'Successfully'
            });
            console.log(response);
            FileDownload(response, 'export.csv');
        })
        .catch((e) => {
            setIsLoading(false);
            setAlert({
              status: 'error',
              message: e.response.message
            });
            console.log(e)
        });
    };

    if (isLoading) {
        return (
            <Box sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: 5
              }}
            >
                <CircularProgress />
            </Box>
        );
    }

    return (
        <>
            <Helmet>
                <title>Customers export | Material Kit</title>
            </Helmet>
            <Box sx={{ backgroundColor: 'background.default', minHeight: '100%' }}>
                <Container maxWidth={false}>
                    <Box sx={{ py: 2 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', pb: 1 }}>
                            <Box style={{ marginTop: 3, marginRight: 10 }}>
                                <Button startIcon={<ChevronLeft />} onClick={() => navigate(-1)}>
                                    Back
                                </Button>
                            </Box>
                        </Box>
                        <Breadcrumbs aria-label="breadcrumb" style={{ fontSize: 12 }}>
                            <RouterLink underline="hover" color="inherit" to="/app/customers">
                                Customers
                            </RouterLink>
                            <p>Export</p>
                        </Breadcrumbs>
                    </Box>
                    <Card sx={{ mb: 2 }}>
                        <CardHeader title="Customers export" titleTypographyProps={{ variant: 'h5' }} component="h1" />
                        <Divider />
                        <CardContent>
                            <FormControl fullWidth>
                                <InputLabel id="statusVurvID">Status VurvID</InputLabel>
                                <Select
                                  labelId="statusVurvID"
                                  name="statusVurvID"
                                  value={statusVurvID}
                                  label="Status VurvID"
                                  onChange={handleStatusVurvID}
                                >
                                    <MenuItem value="active">Active VurvID</MenuItem>
                                    <MenuItem value="usual">Inactive VurvID</MenuItem>
                                </Select>
                            </FormControl>
                            { (alert.status || alert.message)
                            ? (
                                <CustomerAlert status={alert.status} message={alert.message} onClose={() => setAlert({ status: '', message: '' })} />
                            )
                            : undefined}
                        </CardContent>
                        <Divider />
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
                            <Button
                              color="primary"
                              variant="contained"
                              onClick={handleExport}
                            >
                                Export
                            </Button>
                        </Box>
                    </Card>
                </Container>
            </Box>
        </>
    );
};

export default CustomerExport;
