import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import {
  Box,
  TextField,
  Container,
  CardContent,
  CardHeader,
  Card,
  Divider,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Breadcrumbs,
  Checkbox
} from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import {
  usePut,
  useGet,
  usePost
} from '../request';
import GroupAlert from '../components/group/GroupAlert';

const GroupSettings = () => {
    const [groupId, setGroupsId] = useState('');
    const [groupsList, setGroupsList] = useState([]);
    const [alert, setAlert] = useState({
      status: '',
      message: ''
    });
    const navigate = useNavigate();
    const post = usePost();
    const get = useGet();

    const handleGroupsId = (event) => {
        setGroupsId(event.target.value);
    };

    const loadFirstValue = () => {
        get('admin/get-configs')
        .then((response) => {
            if (response.result === 'success') {
                setGroupsId(response.configList.find((group) => group.name === 'stripe_reg_group')?.value || '');
            }
        })
        .catch((e) => console.log(e));
    };

    const loadGroups = () => {
        get('admin/groups')
        .then((response) => {
            if (response.result === 'success') {
                setGroupsList(response.groups);
            }
        })
        .catch((e) => console.log(e));
    };

    const submit = () => {
      const requestData = {
        name: 'stripe_reg_group',
        value: groupId,
      };

      post('admin/update-config', requestData)
      .then((response) => {
        if (response.result === 'success') {
          setAlert({
            status: response.result,
            message: 'Successfully'
          });
          return
        }
        setAlert({
          status: response.result,
          message: response.message
        });
      })
      .catch((e) => console.log(e));
    };

    useEffect(() => {
      loadGroups();
      loadFirstValue();
    }, []);

    return (
      <>
      <Helmet>
        <title>Group settings | Material Kit</title>
      </Helmet>
        <Box sx={{ backgroundColor: 'background.default', minHeight: '100%' }}>
            <Container maxWidth={false}>
                <Box sx={{ py: 2 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', pb: 1 }}>
                        <Box style={{ marginTop: 3, marginRight: 10 }}>
                            <Button startIcon={<ChevronLeft />} onClick={() => navigate(-1)}>
                                Back
                            </Button>
                        </Box>
                    </Box>
                    <Breadcrumbs aria-label="breadcrumb" style={{ fontSize: 12 }}>
                        <RouterLink underline="hover" color="inherit" to="/app/groups">
                            Groups
                        </RouterLink>
                        <p>Settings</p>
                    </Breadcrumbs>
                </Box>
                <form>
                <Card>
                    <CardHeader
                      title="Settings group"
                    />
                    <Divider />
                    <CardContent>
                    <Typography variant="h6" gutterBottom>
                      Group to put new users from Stripe
                    </Typography>
                      <FormControl fullWidth sx={{ mt: 2, mb: 1 }}>
                        <InputLabel id="GroupsId">Groups</InputLabel>
                        <Select
                          labelId="GroupsId"
                          name="GroupsId"
                          value={groupId}
                          label="Groups"
                          onChange={handleGroupsId}
                        >
                          {groupsList.map((group) => (
                            <MenuItem key={group.id} value={group.id}>{group.name}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </CardContent>
                    <Divider />
                    <GroupAlert status={alert.status} message={alert.message} onClose={() => setAlert({ status: '', message: '' })} />
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
                        <Button
                          color="primary"
                          variant="contained"
                          onClick={submit}
                        >
                            Apply
                        </Button>
                    </Box>
                </Card>
                </form>
            </Container>
        </Box>
      </>
    );
};

export default GroupSettings;
