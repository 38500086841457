import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import moment from 'moment';
import {
    Box,
    Container,
    Button,
    Card,
    CardContent,
    Divider,
    Typography,
    Breadcrumbs,
    CircularProgress
} from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ClearIcon from '@material-ui/icons/Clear';
import { makeStyles } from '@material-ui/styles';
import { Link as RouterLink, useParams, useNavigate } from 'react-router-dom';
import { useGet, useHttpDelete, usePost } from '../request';
import { uploadURL } from '../enviroment';
import { useConfirm } from '../components/confirm';
import { useAlert } from '../components/alert';

const useStyles = makeStyles({
    wrap: {
        flexDirection: 'row',
        display: 'flex',
        alignItems: 'center',
        padding: 10
    },
    wrapInner: {
        flex: 1,
        flexDirection: 'row',
        display: 'flex',
        marginRight: 40,
        alignItems: 'center'
    },
    label: {
        width: 200,
        fontSize: 18,
        fontFamily: 'Roboto',
        fontWeight: 'bold'
    },
    labelInner: {
        fontSize: 18,
        fontFamily: 'Roboto',
        fontWeight: 'bold',
        marginRight: 10
    },
    text: {
        fontSize: 18,
        fontFamily: 'Roboto'
    },
});

const Broker = () => {
    const { id } = useParams();
    const [isShowLoader, setIsShowLoader] = useState(true);
    const [broker, setBroker] = useState({});
    const [countActiveGroup, setCountActiveGroup] = useState(null);
    const [countDeactiveGroup, setCountDeactiveGroup] = useState(null);
    const [countActiveMembers, setCountActiveMembers] = useState(null);
    const [countDeactiveMembers, setCountDeactiveMembers] = useState(null);
    const styles = useStyles();
    const get = useGet();
    const post = usePost();
    const navigate = useNavigate();
    const httpDelete = useHttpDelete();
    const alert = useAlert();
    const confirm = useConfirm();

    useEffect(() => {
        setIsShowLoader(true);

        const p1 = get(`admin/brokers/${id}`)
        .then((response) => {
            if (response.result !== 'success') {
                return;
            }
            setBroker(response.data);
        })

        const p2 = post(`admin/brokers/${id}/count-groups`, { isActive: 1 })
        .then((response) => {
            if (response.result !== 'success') {
                return;
            }
            setCountActiveGroup(response.count);
        })

        const p3 = post(`admin/brokers/${id}/count-groups`, { isActive: 0 })
        .then((response) => {
            if (response.result !== 'success') {
                return;
            }
            setCountDeactiveGroup(response.count);
        })

        const p4 = post(`admin/brokers/${id}/count-members`, { onlyActive: true })
        .then((response) => {
            if (response.result !== 'success') {
                return;
            }
            setCountActiveMembers(response.count);
        })

        const p5 = post(`admin/brokers/${id}/count-members`, { onlyDeactive: true })
        .then((response) => {
            if (response.result !== 'success') {
                return;
            }
            setCountDeactiveMembers(response.count);
        })

        Promise.all([p1, p2, p3, p4, p5])
        .then(() => {
            setIsShowLoader(false);
        })
        .catch((e) => {
            setIsShowLoader(false);
            console.log(e)
        });
    }, []);

    const deleteBroker = () => {
        confirm({
            title: 'Deleting',
            content: 'Are you sure you want to delete the broker?',
            isDeleting: true,
            onConfirm: () => {
                httpDelete(`admin/brokers/${id}`)
                .then((response) => {
                    if (response.result === 'error') {
                        alert({
                            status: 'error',
                            message: response.message
                        });
                        return;
                    }
                    navigate(-1)
                })
                .catch((e) => console.log(e));
            }
        });
    };

    if (isShowLoader) {
        return (
            <Box sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: 5
                }}
            >
                  <CircularProgress />
            </Box>
        );
    }

    return (
        <>
            <Helmet>
                <title>Broker | Material Kit</title>
            </Helmet>
            <Box sx={{ backgroundColor: 'background.default', minHeight: '100%' }}>
                <Container maxWidth={false}>
                    <Box sx={{ py: 2 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', pb: 1 }}>
                            <Box style={{ marginTop: 3, marginRight: 10 }}>
                                <Button startIcon={<ChevronLeft />} onClick={() => navigate(-1)}>
                                    Back
                                </Button>
                            </Box>
                            <Typography color="textPrimary" variant="h4" component="h1">
                                {broker.name}
                            </Typography>
                        </Box>
                        <Breadcrumbs aria-label="breadcrumb" style={{ fontSize: 12 }}>
                            <RouterLink underline="hover" color="inherit" to="/app/brokers">
                                Brokers
                            </RouterLink>
                            <p>{broker.name}</p>
                        </Breadcrumbs>
                    </Box>
                    <Card>
                        <CardContent sx={{ p: 3 }}>
                            <div className={styles.wrap}>
                                <div className={styles.label}>
                                    Address:
                                </div>
                                { (broker.city || broker.state || broker.postal_code) ? (
                                    <div className={styles.text}>
                                        {broker.city ? `${broker.city}` : ''}
                                        {broker.state ? ` ${broker.state}` : ''}
                                        {broker.postal_code ? ` ${broker.postal_code}` : ''}
                                    </div>
                                ) : (
                                    <div className={styles.text}>
                                        ---
                                    </div>
                                )}
                            </div>
                            <div className={styles.wrap}>
                                <div className={styles.label}>
                                    Phone:
                                </div>
                                <div className={styles.text}>
                                    {broker.phone || '---'}
                                </div>
                            </div>
                            <div className={styles.wrap}>
                                <div className={styles.label}>
                                    General contact:
                                </div>
                                <div className={styles.text}>
                                    {broker.general_contact || '---'}
                                </div>
                            </div>
                            <div className={styles.wrap}>
                                <div className={styles.label}>
                                    Email:
                                </div>
                                <div className={styles.text}>
                                    {broker.email || '---'}
                                </div>
                            </div>
                            <div className={styles.wrap}>
                                <div className={styles.label}>
                                    Notes:
                                </div>
                                <div className={styles.text}>
                                    {broker.comments || '---'}
                                </div>
                            </div>
                            <div className={styles.wrap}>
                                <div className={styles.wrapInner}>
                                    <div className={styles.label}>
                                        Groups count:
                                    </div>
                                    <div className={styles.text}>
                                        {broker.groups_count || 0}
                                    </div>
                                </div>
                                <div className={styles.wrapInner}>
                                    <div className={styles.labelInner}>
                                        Active:
                                    </div>
                                    <div className={styles.text}>
                                        {countActiveGroup || 0}
                                    </div>
                                    <RouterLink style={{ marginLeft: 'auto' }} to={`/app/broker/active-group-list/${broker.id}`}>
                                        <Button color="primary" variant="contained" size="small">
                                            View
                                        </Button>
                                    </RouterLink>
                                </div>
                                <div className={styles.wrapInner}>
                                    <div className={styles.labelInner}>
                                        Deactive:
                                    </div>
                                    <div className={styles.text}>
                                        {countDeactiveGroup || 0}
                                    </div>
                                    <RouterLink style={{ marginLeft: 'auto' }} to={`/app/broker/deactive-group-list/${broker.id}`}>
                                        <Button color="primary" variant="contained" size="small">
                                            View
                                        </Button>
                                    </RouterLink>
                                </div>
                                <div className={styles.wrapInner}>
                                    <RouterLink style={{ marginLeft: 'auto' }} to={`/app/broker/group-list/${broker.id}`}>
                                        <Button color="primary" variant="contained" size="small">
                                            Show All Group Names List
                                        </Button>
                                    </RouterLink>
                                </div>
                            </div>
                            <div className={styles.wrap}>
                                <div className={styles.wrapInner}>
                                    <div className={styles.label}>
                                        Members count:
                                    </div>
                                    <div className={styles.text}>
                                        {broker.members_count || 0}
                                    </div>
                                </div>
                                <div className={styles.wrapInner}>
                                    <div className={styles.labelInner}>
                                        Active:
                                    </div>
                                    <div className={styles.text}>
                                        {countActiveMembers || 0}
                                    </div>
                                    <RouterLink style={{ marginLeft: 'auto' }} to={`/app/broker/active-members-list/${broker.id}`}>
                                        <Button color="primary" variant="contained" size="small">
                                            View
                                        </Button>
                                    </RouterLink>
                                </div>
                                <div className={styles.wrapInner}>
                                    <div className={styles.labelInner}>
                                        Deactive:
                                    </div>
                                    <div className={styles.text}>
                                        {countDeactiveMembers || 0}
                                    </div>
                                    <RouterLink style={{ marginLeft: 'auto' }} to={`/app/broker/deactive-members-list/${broker.id}`}>
                                        <Button color="primary" variant="contained" size="small">
                                            View
                                        </Button>
                                    </RouterLink>
                                </div>
                                <div className={styles.wrapInner}>
                                    <RouterLink style={{ marginLeft: 'auto' }} to={`/app/broker/members-list/${broker.id}`}>
                                        <Button color="primary" variant="contained" size="small">
                                            Show All Members List
                                        </Button>
                                    </RouterLink>
                                </div>
                            </div>
                        </CardContent>
                        <Divider />
                        <Box sx={{ display: 'flex', p: 2 }}>
                            <Button color="error" variant="contained" onClick={deleteBroker}>
                                Delete
                            </Button>
                            <RouterLink style={{ marginLeft: 'auto' }} to={`/app/broker/edit/${broker.id}`}>
                                <Button color="primary" variant="contained">
                                    Edit
                                </Button>
                            </RouterLink>
                        </Box>
                    </Card>
                </Container>
            </Box>
        </>
    );
};

export default Broker;
