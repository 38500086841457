import {
  Box,
  Button,
  Card,
  Checkbox,
  CircularProgress,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Link as RouterLink } from 'react-router-dom';
import { useGet, useHttpDelete, usePost } from '../../request';
import { useAlert } from '../alert';
import { useConfirm } from '../confirm';

const GroupListResults = () => {
  const [groups, setGroups] = useState(null);
  const [brokers, setBrokers] = useState([]);
  const [users, setUsers] = useState([]);
  const [brokerName, setBrokerName] = useState('');
  const [name, setName] = useState('');
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [count, setCount] = useState(0);
  const [filterOnlyActive, setFilterOnlyActive] = useState(false);
  const [filterOnlyDeactive, setFilterOnlyDeactive] = useState(false);
  const alert = useAlert();
  const post = usePost();
  const get = useGet();
  const httpDelete = useHttpDelete();
  const confirm = useConfirm();

  const loadGroups = () => {
    const requestData = {
      filters: {
        limit,
        page
      }
    };

    if (brokerName) {
      requestData.filters.broker_name = brokerName;
    }

    if (name) {
      requestData.filters.name = name;
    }

    if (filterOnlyActive || filterOnlyDeactive) {
      requestData.filters.isActived = filterOnlyActive || !filterOnlyDeactive;
    }

    post('admin/groups', requestData)
      .then((response) => {
        if (response.result === 'success') {
          setGroups(response.groups);
          setCount(response.count);
        }
      })
      .catch((e) => console.log(e));
  };

  const loadBrokers = () => {
    get('admin/brokers')
      .then((response) => {
        if (response.result === 'success') {
          setBrokers(response.brokers);
        }
      })
      .catch((e) => console.log(e));
  };

  useEffect(() => {
    loadBrokers();
    loadGroups();
  }, []);

  const deleteGroup = (id) => {
    confirm({
      title: 'Deleting',
      content: 'Are you sure you want to delete the group?',
      isDeleting: true,
      onConfirm: () => {
        httpDelete(`admin/groups/${id}`)
          .then((response) => {
            if (response.result === 'error') {
              alert({
                status: 'error',
                message: response.message
              });
              return;
            }
            loadGroups();
          })
          .catch((e) => console.log(e));
      }
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeLimit = (event) => {
    setLimit(event.target.value);
    setPage(0);
  };

  const handleBrokerName = (event) => {
    setBrokerName(event.target.value);
  };

  const handleName = (event) => {
    setName(event.target.value);
  };

  const handleFilter = () => {
    loadGroups();
  };

  const handleFilterOnlyActive = () => {
    setFilterOnlyActive((prev) => !prev);
    setFilterOnlyDeactive(false);
  };

  const handleFilterOnlyDeactive = () => {
    setFilterOnlyDeactive((prev) => !prev);
    setFilterOnlyActive(false);
  };

  useEffect(() => {
    loadGroups();
  }, [page, limit]);

  if (!groups) {
    return (
      <Box
        sx={{
          height: 500,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Card>
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050 }}>
          <Box
            sx={{
              display: 'flex',
              mx: 2,
              mb: 1,
              alignItems: 'center'
            }}
          >
            <TextField
              fullWidth
              label="Name"
              margin="normal"
              name="name"
              onChange={handleName}
              type="text"
              value={name}
              variant="outlined"
              sx={{ mx: 1 }}
            />
            <TextField
              fullWidth
              label="Broker name"
              margin="normal"
              name="brokerName"
              onChange={handleBrokerName}
              type="text"
              value={brokerName}
              variant="outlined"
            />
            <Box sx={{ flex: 1, display: 'flex' }}>
              <Box sx={{ mx: 1, alignItems: 'center', display: 'flex' }}>
                <Checkbox
                  checked={filterOnlyActive}
                  onChange={handleFilterOnlyActive}
                />
                <Typography
                  sx={{ whiteSpace: 'nowrap' }}
                  color="textPrimary"
                  variant="body1"
                >
                  Active
                </Typography>
              </Box>
              <Box sx={{ mx: 1, alignItems: 'center', display: 'flex' }}>
                <Checkbox
                  checked={filterOnlyDeactive}
                  onChange={handleFilterOnlyDeactive}
                />
                <Typography
                  sx={{ whiteSpace: 'nowrap' }}
                  color="textPrimary"
                  variant="body1"
                >
                  Paused
                </Typography>
              </Box>
            </Box>
            <Button
              color="primary"
              variant="contained"
              onClick={handleFilter}
              sx={{ mt: 2, mb: 1, minWidth: 90 }}
            >
              Filter
            </Button>
          </Box>
          <Divider />
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: 80 }}>Id</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Broker</TableCell>
                <TableCell>Status</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {groups.map((group) => (
                <TableRow key={group.id}>
                  <TableCell sx={{ width: 80 }}>{group.id}</TableCell>
                  <TableCell>{group.name}</TableCell>
                  <TableCell>
                    {
                      brokers.find((broker) => broker.id === group.broker_id)
                        ?.name
                    }
                  </TableCell>
                  <TableCell>
                    {!group.isActived ? 'Paused' : 'Actived'}
                  </TableCell>
                  <TableCell>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <RouterLink to={`/app/group/${group.id}`}>
                        <Button color="primary" variant="contained">
                          Info
                        </Button>
                      </RouterLink>
                      <Box sx={{ ml: 2 }}>
                        <RouterLink to={`/app/group/edit/${group.id}`}>
                          <Button color="primary" variant="contained">
                            Edit
                          </Button>
                        </RouterLink>
                      </Box>
                      <Box sx={{ ml: 2 }}>
                        <Button
                          color="primary"
                          variant="contained"
                          onClick={() => deleteGroup(group.id)}
                        >
                          Delete
                        </Button>
                      </Box>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[10, 20, 30, 40, 50]}
                  colSpan={7}
                  count={count}
                  rowsPerPage={limit}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeLimit}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </Box>
      </PerfectScrollbar>
    </Card>
  );
};

export default GroupListResults;

