/* eslint-disable */
import React, { useState, useEffect } from 'react';
import {
    Button,
    Avatar,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow
} from '@material-ui/core';
import { useGet } from '../request';
import { Link as RouterLink } from 'react-router-dom';
import '../styles/AdvertList.css';

const AdvertList = ({ adverts, deleteAd, types }) => {

    return (
        <div className="advert-list">
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell sx={{ width: 80 }}>
                            №
                        </TableCell>
                        <TableCell>
                            Type
                        </TableCell>
                        <TableCell>
                            Image
                        </TableCell>
                        <TableCell>
                            Link
                        </TableCell>
                        <TableCell>
                            Description
                        </TableCell>
                        <TableCell/>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        adverts.map((item, ind) => {
                            return (
                                <TableRow key={item.id}>
                                    <TableCell className="adv-list-cell">
                                        {ind + 1}
                                    </TableCell>
                                    <TableCell>
                                        {types[item.type_id]}
                                    </TableCell>
                                    <TableCell className="adv-list-cell">
                                        <img
                                            src={`${item.image_uri}`}
                                            alt="Изображение"
                                            className="advert-image"
                                        />
                                    </TableCell>
                                    <TableCell className="adv-list-cell">
                                        {item.link ? (
                                            <a href={item.link} className="ad-link">{item.link}</a>
                                        ) : (
                                            types[item.network_redirect]
                                        )}
                                    </TableCell>

                                    <TableCell className="adv-list-cell">
                                        {item.description.length > 20 ? item.description.substring(0, 20) + '...' : item.description || '---'}
                                    </TableCell>
                                    <TableCell className="adv-list-cell controls-cell">
                                        <RouterLink to={`/app/adverts/edit/${item.id}`}>
                                            <Button color="primary"
                                                    variant="contained"
                                                    className="control-btn"
                                            >edit</Button>
                                        </RouterLink>
                                        <Button color="primary"
                                                variant="contained"
                                                className="control-btn del-btn"
                                                onClick={() => deleteAd(item.id)}
                                        >delete</Button>
                                    </TableCell>
                                </TableRow>
                            );
                        })
                    }
                </TableBody>
            </Table>
        </div>
    );
};
export default AdvertList;
