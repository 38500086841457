import {
  Box,
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Container,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography
} from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import GroupImportList from 'src/components/group/GroupImportList';
import { capitalizeFirstLetter } from 'src/utils/capitalizeFirstLetter';
import { useAlert } from '../components/alert/index';
import GroupAlert from '../components/group/GroupAlert';
import { useGet, usePost } from '../request';

const GroupImport = () => {
  const { id } = useParams();
  const [group, setGroup] = useState(null);
  const [file, setFile] = useState(null);
  const [operationLog, setOperationLog] = useState(null);
  const get = useGet();
  const post = usePost();
  const [isLoadingImport, setIsLoadingImport] = useState(false);
  const [filterMethod, setFilterMethod] = useState('default');

  const [alert, setAlert] = useState({
    status: '',
    message: ''
  });
  const notificationAlert = useAlert();
  const navigate = useNavigate();
  const inputImport = useRef();

  const handleFilterMethodChange = (event) => {
    setFilterMethod(event.target.value);
  };

  const handleFile = (event) => {
    setFile(event.target.files[0]);
  };

  useEffect(() => {
    get(`admin/groups/${id}`)
      .then((response) => {
        if (response.result === 'success') {
          setGroup(response.group);
        }
      })
      .catch((e) => console.log(e));
  }, []);

  const handleImport = () => {
    setIsLoadingImport(true);
    const formData = new FormData();
    formData.append('file', file);

    const headers = {
      'Content-Type': 'multipart/form-data'
    };

    let currentEndPoint = '';

    if (filterMethod === 'default') {
      currentEndPoint = `admin/bulk-upload?group_id=${id}`;
    }
    if (filterMethod === 'recuroBulk') {
      currentEndPoint = `admin/bulk-upload/recuro?group_id=${id}`;
    }

    post(currentEndPoint, formData, { headers })
      .then((response) => {
        setIsLoadingImport(false);
        setFile(null);
        inputImport.current.value = '';
        if (response.result !== 'success') {
          notificationAlert({
            status: 'error',
            message: response.message
          });
          return;
        }

        setOperationLog(response.operationLog);

        setAlert({
          status: 'info',
          message: `Success added ${response.added} users! Not added ${response.not_added} users!`
        });

        notificationAlert({
          status: response.result,
          message: response.message
        });
      })
      .catch((e) => {
        console.log(e);
        setIsLoadingImport(false);
      });
  };

  if (!group || isLoadingImport) {
    return (
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 100
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <Helmet>
        <title>Group import | Material Kit</title>
      </Helmet>
      <PerfectScrollbar>
        <Box sx={{ backgroundColor: 'background.default', minHeight: '100%' }}>
          <Container maxWidth={false}>
            <Box sx={{ py: 2 }}>
              <Box sx={{ display: 'flex', alignItems: 'center', pb: 1 }}>
                <Box style={{ marginTop: 3, marginRight: 10 }}>
                  <Button
                    startIcon={<ChevronLeft />}
                    onClick={() => navigate(-1)}
                  >
                    Back
                  </Button>
                </Box>
                <Typography color="textPrimary" variant="h4" component="h1">
                  {group.name}
                </Typography>
              </Box>
              <Breadcrumbs aria-label="breadcrumb" style={{ fontSize: 12 }}>
                <RouterLink underline="hover" color="inherit" to="/app/groups">
                  Groups
                </RouterLink>
                <RouterLink
                  underline="hover"
                  color="inherit"
                  to={`/app/group/${group.id}`}
                >
                  {group.name}
                </RouterLink>
                <p>Import</p>
              </Breadcrumbs>
            </Box>
            <Card sx={{ mb: 2 }}>
              <CardHeader
                title="Import"
                titleTypographyProps={{ variant: 'h5' }}
                component="h2"
              />
              <Divider />
              <Paper sx={{ p: 4 }}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} sm={6} md={4}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel id="filter-method-label">
                        Filter method
                      </InputLabel>
                      <Select
                        labelId="filter-method-label"
                        id="filter-method"
                        value={filterMethod}
                        onChange={handleFilterMethodChange}
                        label="Import Type"
                      >
                        <MenuItem value="default">Default</MenuItem>
                        <MenuItem value="recuroBulk">Recuro Bulk</MenuItem>
                      </Select>
                    </FormControl>
                    <CardContent sx={{ position: 'relative' }}>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Button
                          variant="contained"
                          component="label"
                          sx={{ marginRight: 2, backgroundColor: '#646db5' }}
                        >
                          Select File
                          <input
                            ref={inputImport}
                            type="file"
                            onChange={handleFile}
                            hidden
                          />
                        </Button>
                        {file && (
                          <Typography color="textPrimary" variant="body1">
                            {file.name}
                          </Typography>
                        )}
                      </Box>
                      {alert.status || alert.message ? (
                        <GroupAlert
                          status={alert.status}
                          message={alert.message}
                          onClose={() => setAlert({ status: '', message: '' })}
                          title={capitalizeFirstLetter(alert.status)}
                        />
                      ) : undefined}
                    </CardContent>
                  </Grid>
                </Grid>

                {file && !isLoadingImport && (
                  <Box>
                    <Divider />
                    <Box
                      sx={{ p: 2, display: 'flex', justifyContent: 'flex-end' }}
                    >
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={handleImport}
                      >
                        Import
                      </Button>
                    </Box>
                  </Box>
                )}

                {operationLog && (
                  <GroupImportList operationLog={operationLog} />
                )}
              </Paper>
            </Card>
          </Container>
        </Box>
      </PerfectScrollbar>
    </>
  );
};

export default GroupImport;

