/* eslint-disable */
import React from 'react';
import AdvertTiles from './AdvertTiles';
import AdvertList from './AdvertList';

const AdvertsView = function ({adverts, viewType, deleteAd, types}) {
	switch (viewType) {
      case 'Tile':
        return <AdvertTiles adverts={adverts} deleteAd={deleteAd}/>;
      case 'List':
        return <AdvertList adverts={adverts} deleteAd={deleteAd} types={types}/>;
    }
};

export default AdvertsView;
