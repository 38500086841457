import {
  Box,
  Breadcrumbs,
  Button,
  Container,
  Typography
} from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import FreeCustomesList from 'src/components/customer/FreeCustomesList';

const FreeCustomers = ({ children }) => {
  const navigate = useNavigate();
  return (
    <>
      <Helmet>
        <title>Free customers | Material Kit</title>
      </Helmet>
      <Box sx={{ backgroundColor: 'background.default', minHeight: '100%' }}>
        <Container maxWidth={false}>
          <Box sx={{ py: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', pb: 1 }}>
              <Box style={{ marginTop: 3, marginRight: 10 }}>
                <Button
                  startIcon={<ChevronLeft />}
                  onClick={() => navigate(-1)}
                >
                  Back
                </Button>
              </Box>
              <Typography color="textPrimary" variant="h4" component="h1">
                Free customers
              </Typography>
            </Box>
            <Breadcrumbs aria-label="breadcrumb" style={{ fontSize: 12 }}>
              <p>Free customers</p>
            </Breadcrumbs>
          </Box>
          <FreeCustomesList permissionToRecover>{children}</FreeCustomesList>
        </Container>
      </Box>
    </>
  );
};

FreeCustomers.propTypes = {
  children: PropTypes.node
};

export default FreeCustomers;

