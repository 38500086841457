import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Bar } from 'react-chartjs-2';
import moment from 'moment';
import {
    Box,
    Avatar,
    Container,
    Button,
    Breadcrumbs,
    Card,
    CardContent,
    CircularProgress,
    Typography,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody
} from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import { Link as RouterLink, useParams, useNavigate } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { useGet, usePost } from '../request';
import getInitials from '../utils/getInitials';
import getAvatar from '../utils/getAvatar';
import getPrice from '../utils/getPrice';

const useStyles = makeStyles({
    wrap: {
        flexDirection: 'row',
        display: 'flex',
        padding: 10
    },
    wrapAvatar: {
        alignItems: 'center',
        flexDirection: 'row',
        display: 'flex',
        padding: 10
    },
    label: {
        width: 200,
        fontSize: 18,
        fontFamily: 'Roboto',
        fontWeight: 'bold'
    },
    text: {
        fontSize: 18,
        fontFamily: 'Roboto'
    }
});

const CustomerPaymentsHistory = () => {
    const { id } = useParams();
    const [customer, setCustomer] = useState(null);
    const [payments, setPayments] = useState([]);
    const [data, setData] = useState(null);
    const styles = useStyles();
    const get = useGet();
    const post = usePost();
    const navigate = useNavigate();
    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            }
        },
    };

    const labels = [
        moment().subtract(5, 'months'),
        moment().subtract(4, 'months'),
        moment().subtract(3, 'months'),
        moment().subtract(2, 'months'),
        moment().subtract(1, 'months'),
        moment().subtract(0, 'months'),
    ];

    const labelsFormat = labels.map((item) => item.format('MMM'));

    useEffect(() => {
        setData({
            labels: labelsFormat,
            datasets: [
                {
                    label: 'Amount ($)',
                    data: labels.map((item) => payments.reduce((v, payment) => {
                        if (item.format('YYYY MM') === moment(payment.payment_date).format('YYYY MM')) {
                            return v + payment.count / 100;
                        }
                        return v;
                    }, 0)),
                    backgroundColor: 'rgba(255, 99, 132, 0.5)',
                },
            ],
        })
    }, [payments]);

    const SyncPayments = () => {
        post(`admin/check-user-payments/${id}`)
            .then((response) => {
                if (response.result === 'success') {
                    alert('Success');
                }
            })
            .catch((e) => console.log(e));
    };

    useEffect(() => {
        get(`get-user/${id}`)
        .then((response) => {
            if (response.result !== 'success') {
                return;
            }
            setCustomer(response.user);
        })
        .catch((e) => console.log(e));
        get(`user/payments-history/${id}`)
        .then((response) => {
            if (response.result !== 'success') {
                return;
            }
            setPayments(response?.payments || []);
        })
        .catch((e) => console.log(e));
    }, []);

    if (!customer) {
        return (
            <Box sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: 100
            }}
            >
                <CircularProgress />
            </Box>
        );
    }

    const renderPayments = () => {
        if (payments.length === 0) {
            return (
                <Typography color="textPrimary" variant="body1" component="p" sx={{ p: 2, textAlign: 'center', backgroundColor: '#fff' }}>
                    Payments not found
                </Typography>
            );
        }
        return (
            <Table sx={{ backgroundColor: '#fff', boxShadow: '0 0 0 1px rgb(63, 63, 68, 0.1)', borderRadius: '4px' }}>
                <TableHead>
                    <TableRow>
                        <TableCell sx={{ width: 120 }}>
                            Id
                        </TableCell>
                        <TableCell>
                            Date
                        </TableCell>
                        <TableCell>
                            Payment id
                        </TableCell>
                        <TableCell>
                            Count
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        payments.map((item) => (
                            <TableRow key={item.id}>
                                <TableCell sx={{ width: 120 }}>
                                    {item.id}
                                </TableCell>
                                <TableCell>
                                    {moment(item.payment_date).format('DD.MM.YYYY HH:mm') }
                                </TableCell>
                                <TableCell>
                                    {item.payment_id}
                                </TableCell>
                                <TableCell>
                                    {getPrice(item.count)}
                                </TableCell>
                            </TableRow>
                        ))
                    }
                </TableBody>
            </Table>
        );
    };

    return (
        <>
            <Helmet>
                <title>Customer payments history | Material Kit</title>
            </Helmet>
            <Box sx={{ backgroundColor: 'background.default', minHeight: '100%' }}>
                <Container maxWidth={false} sx={{ paddingBottom: 3 }}>
                    <Box sx={{ py: 2 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', pb: 1 }}>
                            <Box style={{ marginTop: 3, marginRight: 10 }}>
                                <Button startIcon={<ChevronLeft />} onClick={() => navigate(-1)}>
                                    Back
                                </Button>
                            </Box>
                        </Box>
                        <Breadcrumbs aria-label="breadcrumb" style={{ fontSize: 12 }}>
                            <RouterLink underline="hover" color="inherit" to="/app/customers">
                                Customers
                            </RouterLink>
                            <RouterLink underline="hover" color="inherit" to={`/app/customer/${id}`}>
                                {`${customer.user_first_name} ${customer.user_last_name}`}
                            </RouterLink>
                            <p>Payments History</p>
                        </Breadcrumbs>
                        <Box sx={{ display: 'flex', flex: 1, justifyContent: 'flex-end' }}>
                            <RouterLink onClick={() => SyncPayments()}>
                                <Button color="info" variant="contained">
                                    Manual Sync User Payments
                                </Button>
                            </RouterLink>
                        </Box>
                    </Box>
                    <Card>
                        <CardContent sx={{ p: 3 }}>
                            <div className={styles.wrapAvatar}>
                                <Avatar src={getAvatar(customer)} sx={{ width: 200, height: 200, mr: 2 }}>
                                    {getInitials(customer.user_login ? customer.user_login : `${customer.user_last_name} ${customer.user_first_name}`)}
                                </Avatar>
                                <div>
                                    <div>
                                        <div className={styles.wrap}>
                                            <div className={styles.label}>
                                                First name:
                                            </div>
                                            <div className={styles.text}>
                                                {customer.user_first_name || '---'}
                                            </div>
                                        </div>
                                        <div className={styles.wrap}>
                                            <div className={styles.label}>
                                                Last name:
                                            </div>
                                            <div className={styles.text}>
                                                {customer.user_last_name || '---'}
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className={styles.wrap}>
                                            <div className={styles.label}>
                                                Email:
                                            </div>
                                            <div className={styles.text}>
                                                {customer.user_email || '---'}
                                            </div>
                                        </div>
                                        <div className={styles.wrap}>
                                            <div className={styles.label}>
                                                Phone:
                                            </div>
                                            <div className={styles.text}>
                                                {customer.user_phone || '---'}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </CardContent>
                    </Card>
                    {data && (
                        <Box sx={{ maxWidth: 600, margin: '20px auto' }}>
                            <Bar options={options} data={data} />
                        </Box>
                    )}
                    {renderPayments()}
                </Container>
            </Box>
        </>
    );
};

export default CustomerPaymentsHistory;
