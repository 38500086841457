import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import {
    Box,
    Avatar,
    Container,
    Button,
    Breadcrumbs,
    Card,
    CardContent,
    CircularProgress,
    Typography,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody
} from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import { Link as RouterLink, useParams, useNavigate } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { useGet, usePost } from '../request';
import getInitials from '../utils/getInitials';
import getAvatar from '../utils/getAvatar';

const useStyles = makeStyles({
    wrap: {
        flexDirection: 'row',
        display: 'flex',
        padding: 10
    },
    wrapAvatar: {
        alignItems: 'center',
        flexDirection: 'row',
        display: 'flex',
        padding: 10
    },
    label: {
        width: 200,
        fontSize: 18,
        fontFamily: 'Roboto',
        fontWeight: 'bold'
    },
    text: {
        fontSize: 18,
        fontFamily: 'Roboto'
    }
});

const CustomerHistory = () => {
    const { id } = useParams();
    const [customer, setCustomer] = useState(null);
    // const [history, setHistory] = useState([
    //     {
    //     id: 'test',
    //     message: 'test',
    //     date_create: 'test'
    //     }
    // ]);
    const [history, setHistory] = useState([]);
    const styles = useStyles();
    const get = useGet();
    const post = usePost();
    const navigate = useNavigate();

    useEffect(() => {
            get(`get-user/${id}`)
            .then((response) => {
                if (response.result !== 'success') {
                    return;
                }
                setCustomer(response.user);
            })
            .catch((e) => console.log(e));
            get(`users/${id}/history`)
            .then((response) => {
                if (response.result !== 'success') {
                    return;
                }
                setHistory(response.history);
            })
            .catch((e) => console.log(e));
    }, []);

    if (!customer) {
        return (
            <Box sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: 100
            }}
            >
                <CircularProgress />
            </Box>
        );
    }

    const renderHistory = () => {
        if (history.length === 0) {
            return (
                <Typography color="textPrimary" variant="body1" component="p" sx={{ p: 2, textAlign: 'center', backgroundColor: '#fff' }}>
                    History not found
                </Typography>
            );
        }
        return (
            <Box>
                <Table sx={{ backgroundColor: '#fff', boxShadow: '0 0 0 1px rgb(63, 63, 68, 0.1)', borderRadius: '4px' }}>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ width: 120 }}>
                                Id
                            </TableCell>
                            <TableCell>
                                Date
                            </TableCell>
                            <TableCell>
                                Message
                            </TableCell>
                            <TableCell />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            history.map((item) => (
                                <TableRow key={item.id}>
                                    <TableCell sx={{ width: 120 }}>
                                        {item.id}
                                    </TableCell>
                                    <TableCell sx={{ width: 200 }}>
                                        {item.date}
                                    </TableCell>
                                    <TableCell>
                                        {item.log}
                                    </TableCell>
                                </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>
            </Box>
        );
    };

    return (
        <>
            <Helmet>
                <title>Customer history | Material Kit</title>
            </Helmet>
            <Box sx={{ backgroundColor: 'background.default', minHeight: '100%' }}>
                <Container maxWidth={false}>
                    <Box sx={{ py: 2 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', pb: 1 }}>
                            <Box style={{ marginTop: 3, marginRight: 10 }}>
                                <Button startIcon={<ChevronLeft />} onClick={() => navigate(-1)}>
                                    Back
                                </Button>
                            </Box>
                        </Box>
                        <Breadcrumbs aria-label="breadcrumb" style={{ fontSize: 12 }}>
                            <RouterLink underline="hover" color="inherit" to="/app/customers">
                                Customers
                            </RouterLink>
                            <RouterLink underline="hover" color="inherit" to={`/app/customer/${id}`}>
                                {`${customer.user_first_name} ${customer.user_last_name}`}
                            </RouterLink>
                            <p>History</p>
                        </Breadcrumbs>
                    </Box>
                    <Card>
                        <CardContent sx={{ p: 3 }}>
                            <div className={styles.wrapAvatar}>
                                <Avatar src={getAvatar(customer)} sx={{ width: 200, height: 200, mr: 2 }}>
                                    {getInitials(customer.user_login ? customer.user_login : `${customer.user_last_name} ${customer.user_first_name}`)}
                                </Avatar>
                                <div>
                                    <div>
                                        <div className={styles.wrap}>
                                            <div className={styles.label}>
                                                First name:
                                            </div>
                                            <div className={styles.text}>
                                                {customer.user_first_name || '---'}
                                            </div>
                                        </div>
                                        <div className={styles.wrap}>
                                            <div className={styles.label}>
                                                Last name:
                                            </div>
                                            <div className={styles.text}>
                                                {customer.user_last_name || '---'}
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className={styles.wrap}>
                                            <div className={styles.label}>
                                                Email:
                                            </div>
                                            <div className={styles.text}>
                                                {customer.user_email || '---'}
                                            </div>
                                        </div>
                                        <div className={styles.wrap}>
                                            <div className={styles.label}>
                                                Phone:
                                            </div>
                                            <div className={styles.text}>
                                                {customer.user_phone || '---'}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </CardContent>
                    </Card>
                    {renderHistory()}
                </Container>
            </Box>
        </>
    );
};

export default CustomerHistory;
