import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
    Box,
    Card,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Button,
    Checkbox,
    Typography,
    CircularProgress
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { useConfirm } from '../confirm';
import { useAlert } from '../alert';
import {
    useGet,
    useHttpDelete
} from '../../request';

const GroupListResultsExpiresExpirationDate = () => {
    const [groups, setGroups] = useState(null);
    const [brokers, setBrokers] = useState([]);
    const get = useGet();
    const httpDelete = useHttpDelete();
    const alert = useAlert();
    const confirm = useConfirm();

    const loadGroups = () => {
        get('admin/group/get-expired')
        .then((response) => {
            if (response.result === 'success') {
                setGroups(response.groups);
            }
        })
        .catch((e) => console.log(e));
    };

    const loadBrokers = () => {
        get('admin/brokers')
        .then((response) => {
            if (response.result === 'success') {
                setBrokers(response.brokers);
            }
        })
        .catch((e) => console.log(e));
    };

    useEffect(() => {
        loadBrokers();
        loadGroups();
    }, []);

    const deleteGroup = (id) => {
        confirm({
            title: 'Deleting',
            content: 'Are you sure you want to delete the group?',
            isDeleting: true,
            onConfirm: () => {
                httpDelete(`admin/groups/${id}`)
                .then((response) => {
                    if (response.result === 'error') {
                        alert({
                            status: 'error',
                            message: response.message
                        });
                        return;
                    }
                    loadGroups()
                })
                .catch((e) => console.log(e));
            }
        });
    };

    if (!groups) {
        return (
            <Box sx={{
                height: 500,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                }}
            >
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Card>
            <PerfectScrollbar>
                <Box sx={{ minWidth: 1050 }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ width: 80 }}>
                                    Id
                                </TableCell>
                                <TableCell>
                                    Name
                                </TableCell>
                                <TableCell>
                                    Broker
                                </TableCell>
                                <TableCell>
                                    Status
                                </TableCell>
                                <TableCell />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {groups.map((group) => (
                                <TableRow key={group.id}>
                                    <TableCell sx={{ width: 80 }}>
                                        {group.id}
                                    </TableCell>
                                    <TableCell>
                                        {group.name}
                                    </TableCell>
                                    <TableCell>
                                        {brokers.find((broker) => broker.id === group.broker_id)?.name}
                                    </TableCell>
                                    <TableCell>
                                        {!group.isActived ? 'Paused' : 'Actived'}
                                    </TableCell>
                                    <TableCell>
                                        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                            <RouterLink to={`/app/group/${group.id}`}>
                                                <Button color="primary" variant="contained">
                                                    Info
                                                </Button>
                                            </RouterLink>
                                            <Box sx={{ ml: 2 }}>
                                                <RouterLink to={`/app/group/edit/${group.id}`}>
                                                    <Button color="primary" variant="contained">
                                                        Edit
                                                    </Button>
                                                </RouterLink>
                                            </Box>
                                            <Box sx={{ ml: 2 }}>
                                                <Button color="primary" variant="contained" onClick={() => deleteGroup(group.id)}>
                                                    Delete
                                                </Button>
                                            </Box>
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Box>
            </PerfectScrollbar>
        </Card>
    );
}

export default GroupListResultsExpiresExpirationDate;
