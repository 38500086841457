import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import {
  Container,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  Typography,
  Breadcrumbs,
  Checkbox,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableBody
} from '@material-ui/core';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import { useGet, usePost } from '../request';
import SettingsAlert from '../components/settings/SettingsAlert';
import { TableChart } from '@material-ui/icons';
import PerfectScrollbar from 'react-perfect-scrollbar';
import ModalEdit from 'src/components/modalEdit/ModalEdit';

const NAMES_MAP = {
  stripe_api_key: 'Api stripe key',
  mixpanel_project_token: 'Mixpanel project token',
  group_email_notification: 'Group email notification',
  bulkupload_enable_email: 'Bulkupload email campaigns',
  client_id: 'Client id',
  client_secret: 'Client secret',
  'partner user': 'Partner user',
  rxs_sense: 'Rxs sense',
  Marina: 'Marina',
  stripe_reg_group: 'Stripe reg group',
  stripe_api_payment_amount: 'Payment amount in Number (1.00$ = 100)'
};

const Settings = () => {
  const [values, setValues] = useState({
    stripe_api_key: '',
    mixpanel_project_token: '',
    group_email_notification: '',
    bulkupload_enable_email: '0',
    client_id: '',
    client_secret: ''
  });
  const [resultSaved, setResultSaved] = useState({
    stripe_api_key: '',
    mixpanel_project_token: '',
    bulkupload_enable_email: '',
    group_email_notification: '',
    client_id: '',
    client_secret: ''
  });
  const [isLoad, setIsLoad] = useState(true);
  const navigate = useNavigate();
  const post = usePost();
  const get = useGet();
  const [isShowModal, setIsShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState();
  const [modalValue, setModalValue] = useState();
  const [modalName, setModalName] = useState();
  const [isDisabled, setDisabled] = useState(false);

  const showModal = (e) => {
    setModalTitle(NAMES_MAP[e.target.value]);
    setModalValue(values[e.target.value]);
    setModalName(e.target.value);
    setIsShowModal(true);
  };

  const hideModal = () => {
    setIsShowModal(false);
  };

  const handleChange = (event) => {
    setModalValue(event.target.value);
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const handleChangeChecked = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.checked ? '1' : '0'
    });
  };

  const handleResultSaved = (name, value) => {
    // console.log('resultSaved', resultSaved);
    // console.log('resultSaved', name, value);
    setResultSaved({
      ...resultSaved,
      [name]: value
    });
  };

  const saveConfig = (name) => {
    setDisabled(true);

    const requestData = { name, value: values[name] };

    post('admin/update-config', requestData)
      .then((response) => handleResultSaved(name, response.result))
      .catch((e) => console.log(e))
      .finally(() => {
        setDisabled(false);
      });
  };

  useEffect(() => {
    get('admin/get-configs')
      .then((response) => {
        if (response.result !== 'success') {
          return;
        }
        let data = {};
        if (response.configList) {
          response.configList.forEach((conf) => {
            data[conf.name] = conf.value;
          });
        }
        setValues({ ...values, ...data });
        console.log(values);
      })
      .catch((e) => console.log(e));
  }, []);

  return (
    <>
      <ModalEdit
        isOpen={isShowModal}
        onClose={hideModal}
        title={modalTitle}
        value={modalValue}
        handleCange={handleChange}
        name={modalName}
        handleSubmit={saveConfig}
        disabled={isDisabled}
      />
      <Helmet>
        <title>Settings | Material Kit</title>
      </Helmet>
      <Box sx={{ backgroundColor: 'background.default', minHeight: '100%' }}>
        <Container maxWidth={false}>
          <Box sx={{ py: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', pb: 1 }}>
              <Box style={{ marginTop: 3, marginRight: 10 }}>
                <Button
                  startIcon={<ChevronLeft />}
                  onClick={() => navigate(-1)}
                >
                  Back
                </Button>
              </Box>
              <Typography color="textPrimary" variant="h4" component="h1">
                Settings
              </Typography>
            </Box>
            <Breadcrumbs aria-label="breadcrumb" style={{ fontSize: 12 }}>
              <p>Settings</p>
            </Breadcrumbs>
          </Box>
          <PerfectScrollbar>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: 200 }}>Name</TableCell>
                  <TableCell>Value</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.keys(values).map((elem, index) => (
                  <TableRow hover key={index}>
                    <TableCell>{NAMES_MAP[elem]}</TableCell>
                    <TableCell>{values[elem]}</TableCell>
                    <TableCell>
                      <Button
                        onClick={showModal}
                        value={elem}
                        color="primary"
                        variant="contained"
                      >
                        Edit
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </PerfectScrollbar>
          {/* <Card>
                  <CardHeader
                    title="Stripe"
                  />
                  <Divider />
                  <CardContent>
                    <TextField
                      fullWidth
                      label="Api stripe key"
                      margin="normal"
                      name="stripe_api_key"
                      onChange={handleChange}
                      type="text"
                      value={values.stripe_api_key}
                      variant="outlined"
                    />
                    <SettingsAlert status={resultSaved.stripe_api_key} onClose={() => handleResultSaved('stripe_api_key', '')} />
                  </CardContent>
                  <Divider />
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      p: 2
                    }}
                  >
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => saveConfig('stripe_api_key')}
                    >
                      Save
                    </Button>
                  </Box>
                </Card>
                <Card>
                  <CardHeader
                    title="Stripe Payment amount"
                  />
                  <Divider />
                  <CardContent>
                    <TextField
                      fullWidth
                      label="Payment amount in Number (1.00$ = 100)"
                      margin="normal"
                      name="stripe_api_payment_amount"
                      onChange={handleChange}
                      type="text"
                      value={values.stripe_api_payment_amount}
                      variant="outlined"
                    />
                    <SettingsAlert status={resultSaved.stripe_api_payment_amount} onClose={() => handleResultSaved('stripe_api_payment_amount', '')} />
                  </CardContent>
                  <Divider />
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      p: 2
                    }}
                  >
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => saveConfig('stripe_api_payment_amount')}
                    >
                      Save
                    </Button>
                  </Box>
                </Card>
                <Card sx={{ mt: 2 }}>
                  <CardHeader
                    title="Mixpanel"
                  />
                  <Divider />
                  <CardContent>
                    <TextField
                      fullWidth
                      label="Mixpanel project token"
                      margin="normal"
                      name="mixpanel_project_token"
                      onChange={handleChange}
                      type="text"
                      value={values.mixpanel_project_token}
                      variant="outlined"
                    />
                    <SettingsAlert status={resultSaved.mixpanel_project_token} onClose={() => handleResultSaved('mixpanel_project_token', '')} />
                  </CardContent>
                  <Divider />
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      p: 2
                    }}
                  >
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => saveConfig('mixpanel_project_token')}
                    >
                      Save
                    </Button>
                  </Box>
                </Card>

                <Card sx={{ mt: 2 }}>
                  <CardHeader
                    title="This email will be using to sending group expiration and other notifications"
                  />
                  <Divider />
                  <CardContent>
                    <TextField
                      fullWidth
                      label="Group email notification"
                      margin="normal"
                      name="group_email_notification"
                      onChange={handleChange}
                      type="text"
                      value={values.group_email_notification}
                      variant="outlined"
                    />
                    <SettingsAlert status={resultSaved.group_email_notification} onClose={() => handleResultSaved('group_email_notification', '')} />
                  </CardContent>
                  <Divider />
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      p: 2
                    }}
                  >
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => saveConfig('group_email_notification')}
                    >
                      Save
                    </Button>
                  </Box>
                </Card>

                <Card sx={{ mt: 2 }}>
                  <CardHeader
                    title="General"
                  />
                  <Divider />
                  <CardContent>
                    <Box sx={{ alignItems: 'center', display: 'flex' }}>
                      <Checkbox checked={values.bulkupload_enable_email !== '0'} name="bulkupload_enable_email" onChange={handleChangeChecked} />
                      <Typography color="textPrimary" variant="body1">
                          Bulkupload email campaigns
                      </Typography>
                    </Box>
                    <SettingsAlert status={resultSaved.bulkupload_enable_email} onClose={() => handleResultSaved('bulkupload_enable_email', '')} />
                  </CardContent>
                  <Divider />
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      p: 2
                    }}
                  >
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => saveConfig('bulkupload_enable_email')}
                    >
                      Save
                    </Button>
                  </Box>
                </Card> */}

          {/* <Card sx={{ mt: 2 }}>
                  <CardHeader
                    title="RxSense"
                  />
                  <Divider />
                  <CardContent>
                    <Box sx={{ display: 'flex' }}>
                      <TextField
                        fullWidth
                        label="Client id"
                        margin="normal"
                        name="client_id"
                        onChange={handleChange}
                        type="text"
                        value={values.client_id}
                        variant="outlined"
                      />
                      <Button
                        color="primary"
                        variant="contained"
                        sx={{ mt: 2, mb: 1, ml: 1 }}
                        onClick={() => saveConfig('client_id')}
                      >
                        Save
                      </Button>
                    </Box>
                    <SettingsAlert status={resultSaved.client_id} onClose={() => handleResultSaved('client_id', '')} />
                    <Box sx={{ display: 'flex' }}>
                      <TextField
                        fullWidth
                        label="Client secret"
                        margin="normal"
                        name="client_secret"
                        onChange={handleChange}
                        type="text"
                        value={values.client_secret}
                        variant="outlined"
                      />
                      <Button
                        color="primary"
                        variant="contained"
                        sx={{ mt: 2, mb: 1, ml: 1 }}
                        onClick={() => saveConfig('client_secret')}
                      >
                        Save
                      </Button>
                    </Box>
                      <SettingsAlert status={resultSaved.client_secret} onClose={() => handleResultSaved('client_secret', '')} />
                  </CardContent>
                </Card> */}
        </Container>
      </Box>
    </>
  );
};

export default Settings;
