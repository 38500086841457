import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import {
    Box,
    Container,
    Card,
    CardHeader,
    Divider,
    CardContent,
    Typography,
    Button,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    CircularProgress,
    Breadcrumbs,
} from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import { Link as RouterLink, useParams, useNavigate } from 'react-router-dom';
import FileDownload from 'js-file-download';
import { useGet, usePost } from '../request';
import { uploadURL } from '../enviroment';
import GroupAlert from '../components/group/GroupAlert';

const GroupExport = () => {
    const { id } = useParams();
    const [status, setStatus] = useState('active');
    const [isLoading, setIsLoading] = useState(false);
    const [group, setGroup] = useState(null);
    const [alert, setAlert] = useState({
        status: '',
        message: ''
    });
    const get = useGet();
    const post = usePost();
    const navigate = useNavigate();

    const handleStatus = (event) => {
        setStatus(event.target.value);
    };

    useEffect(() => {
        get(`admin/groups/${id}`)
        .then((response) => {
          if (response.result === 'success') {
            setGroup(response.group);
          }
        })
        .catch((e) => console.log(e));
    }, []);

    const handleExport = () => {
        setIsLoading(true);
        post(`admin/groups/export-members/?sort=${status}&groupId=${id}`, {}, { responseType: 'blob' })
        .then((response) => {
            setIsLoading(false);
            setAlert({
                status: 'success',
                message: 'Successfully'
            });
            FileDownload(response, 'export.csv');
        })
        .catch((e) => {
            setIsLoading(false);
            setAlert({
              status: 'error',
              message: e.response.message
            });
            console.log(e);
        });
    };

    if (!group || isLoading) {
        return (
            <Box sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: 5
              }}
            >
                <CircularProgress />
            </Box>
        );
    }

    return (
        <>
            <Helmet>
                <title>Group export | Material Kit</title>
            </Helmet>
            <Box sx={{ backgroundColor: 'background.default', minHeight: '100%' }}>
                <Container maxWidth={false}>
                    <Box sx={{ py: 2 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', pb: 1 }}>
                            <Box style={{ marginTop: 3, marginRight: 10 }}>
                                <Button startIcon={<ChevronLeft />} onClick={() => navigate(-1)}>
                                    Back
                                </Button>
                            </Box>
                            <Typography color="textPrimary" variant="h4" component="h1">
                                {group.name}
                            </Typography>
                        </Box>
                        <Breadcrumbs aria-label="breadcrumb" style={{ fontSize: 12 }}>
                            <RouterLink underline="hover" color="inherit" to="/app/groups">
                                Groups
                            </RouterLink>
                            <RouterLink underline="hover" color="inherit" to={`/app/group/${group.id}`}>
                                {group.name}
                            </RouterLink>
                            <p>Export</p>
                        </Breadcrumbs>
                    </Box>
                    <Card sx={{ mb: 2 }}>
                        <CardHeader title="Group export" titleTypographyProps={{ variant: 'h5' }} component="h1" />
                        <Divider />
                        <CardContent>
                            <FormControl fullWidth>
                                <InputLabel id="status">Status</InputLabel>
                                <Select
                                  labelId="status"
                                  name="status"
                                  value={status}
                                  label="Status"
                                  onChange={handleStatus}
                                >
                                    <MenuItem value="active">Actived</MenuItem>
                                    <MenuItem value="usual">Paused</MenuItem>
                                    <MenuItem value="all">All</MenuItem>
                                </Select>
                            </FormControl>
                            { (alert.status || alert.message)
                            ? (
                                <GroupAlert status={alert.status} message={alert.message} onClose={() => setAlert({ status: '', message: '' })} />
                            )
                            : undefined}
                        </CardContent>
                        <Divider />
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
                            <Button
                              color="primary"
                              variant="contained"
                              onClick={handleExport}
                            >
                                Export
                            </Button>
                        </Box>
                    </Card>
                </Container>
            </Box>
        </>
    );
};

export default GroupExport;
