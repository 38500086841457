import {
  Box,
  Breadcrumbs,
  Button,
  Container,
  Typography
} from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import CustomerListResults from './CustomerListResults';

const CustomerList = ({ children }) => {
  const navigate = useNavigate();
  return (
    <>
      <Helmet>
        <title>Customers | Material Kit</title>
      </Helmet>
      <Box sx={{ backgroundColor: 'background.default', minHeight: '100%' }}>
        <Container maxWidth={false}>
          <Box sx={{ py: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', pb: 1 }}>
              <Box style={{ marginTop: 3, marginRight: 10 }}>
                <Button
                  startIcon={<ChevronLeft />}
                  onClick={() => navigate(-1)}
                >
                  Back
                </Button>
              </Box>
              <Typography color="textPrimary" variant="h4" component="h1">
                Customers
              </Typography>
              <Box sx={{ display: 'flex', marginLeft: 'auto' }}>
                <RouterLink to="/app/customer-import">
                  <Button color="primary" variant="contained">
                    Import
                  </Button>
                </RouterLink>
                <Box sx={{ marginLeft: 1 }}>
                  <RouterLink to="/app/customer-export">
                    <Button color="primary" variant="contained">
                      Export
                    </Button>
                  </RouterLink>
                </Box>
                <Box sx={{ marginLeft: 2 }}>
                  <RouterLink to="/app/customer/add">
                    <Button color="primary" variant="contained">
                      Add customer
                    </Button>
                  </RouterLink>
                </Box>
              </Box>
            </Box>
            <Breadcrumbs aria-label="breadcrumb" style={{ fontSize: 12 }}>
              <p>Customers</p>
            </Breadcrumbs>
          </Box>
          <CustomerListResults filters={{ soft_delete: 0 }}>
            {children}
          </CustomerListResults>
        </Container>
      </Box>
    </>
  );
};

CustomerList.propTypes = {
  children: PropTypes.node
};

export default CustomerList;

