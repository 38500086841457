/* eslint-disable */
import React, { useState, useEffect } from 'react';
import AdvertTile from './AdvertTile';
import '../styles/AdvertTiles.css';
import { useGet } from '../request';

const AdvertTiles = ({adverts, deleteAd}) => {
	const get = useGet();
	const [typeIdConverter, setTypeIdConverter] = useState({});

	useEffect(() => {
		get('admin/provider-ad-types')
		.then((response) => {
			if (response.result !== "success") {
				return;
			}
			const types = {};
			response.types.forEach((item) => {
				types[item.id] = item.type_name;
			})
			setTypeIdConverter(types);
		})
		.catch((e) => console.log(e));
	}, []);

    return (
        <div className="advert-grid">
            {adverts.map(advert => <AdvertTile advertData={advert} typeIdConverter={typeIdConverter} key={advert.id} deleteAd={deleteAd} />)}
        </div>
    );
};

export default AdvertTiles;
