/* eslint-disable */
import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Box, Container, Grid } from '@material-ui/core';
import moment from 'moment';
import Budget from '../components/dashboard/Budget';
import LatestOrders from '../components/dashboard/LatestOrders';
import LatestProducts from '../components/dashboard/LatestProducts';
import GroupsExpired from '../components/dashboard/GroupsExpired';
import GroupsLast from '../components/dashboard/GroupsLast';
import Sales from '../components/dashboard/Sales';
import TasksProgress from '../components/dashboard/TasksProgress';
import TotalCustomers from '../components/dashboard/TotalCustomers';
import TotalProfit from '../components/dashboard/TotalProfit';
import TrafficByDevice from '../components/dashboard/TrafficByDevice';
import CountUsers from '../components/dashboard/CountUsers';
import { useGet } from '../request';
import CheckErrors from '../components/dashboard/CheckErrors';

const Dashboard = () => {
  const [groups, setGroups] = useState({});
  const [brokers, setBrokers] = useState({});
  const [members, setMembers] = useState({});
  const [userStats, setUserStats] = useState(null);
  const get = useGet();

  const loadGetUserStats = () => {
    get('admin/get-user-stats')
      .then((response) => {
        if (response.result === 'success') {
          setUserStats(response);
        } else {
          throw response.message ? response.message : 'Failed fetch user stats';
        }
      })
      .catch((e) => console.log(e));
  };

  const loadMembers = () => {
    get('admin/dashboard/members')
      .then((response) => {
        if (response.result === 'success') {
          setMembers(response);
          loadGetUserStats();
        } else {
          throw response.message
            ? response.message
            : 'Failed fetch dashboard members';
        }
      })
      .catch((e) => console.log(e));
  };

  const loadGroups = () => {
    get('admin/dashboard/groups')
      .then((response) => {
        if (response.result === 'success') {
          setGroups(response);
          loadMembers();
        } else {
          throw response.message
            ? response.message
            : 'Failed fetch dashboard groups';
        }
      })
      .catch((e) => console.log(e));
  };

  const loadBrokers = () => {
    get('admin/dashboard/brokers')
      .then((response) => {
        if (response.result === 'success') {
          setBrokers(response);
          loadGroups();
        } else {
          throw response.message
            ? response.message
            : 'Failed fetch dashboard brokers';
        }
      })
      .catch((e) => console.log(e));
  };

  const getCurrentUser = () => {
    get('get-user')
      .then((response) => {
        if (response.result === 'success') {
          loadBrokers();
        } else {
          throw response.message
            ? response.message
            : 'Failed fetch dashboard get user';
        }
      })
      .catch((e) => console.log(e));
  };

  useEffect(() => {
    getCurrentUser();
  }, []);

  return (
    <>
      <Helmet>
        <title>Dashboard | Material Kit</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <Grid container spacing={3}>
            <Grid item lg={3} sm={6} xl={3} xs={12}>
              <TotalProfit
                count={
                  brokers.brokers_count ? brokers.brokers_count.toString() : '0'
                }
                active={
                  brokers.brokers_members_active
                    ? brokers.brokers_members_active.toString()
                    : '0'
                }
                deactive={
                  brokers.brokers_members_deactive
                    ? brokers.brokers_members_deactive.toString()
                    : '0'
                }
                title="BROKERS"
                sx={{ height: '100%' }}
              />
            </Grid>
            <Grid item lg={3} sm={6} xl={3} xs={12}>
              <TotalProfit
                count={
                  groups.groups_count ? groups.groups_count.toString() : '0'
                }
                active={
                  groups.groups_active ? groups.groups_active.toString() : '0'
                }
                deactive={
                  groups.groups_deactive
                    ? groups.groups_deactive.toString()
                    : '0'
                }
                title="GROUPS"
                sx={{ height: '100%' }}
              />
            </Grid>
            <Grid item lg={3} sm={6} xl={3} xs={12}>
              <TotalProfit
                count={
                  members.members_count ? members.members_count.toString() : '0'
                }
                active={
                  members.members_active
                    ? members.members_active.toString()
                    : '0'
                }
                deactive={
                  members.members_deactive
                    ? members.members_deactive.toString()
                    : '0'
                }
                title="MEMBERS"
                sx={{ height: '100%' }}
              />
            </Grid>
            <Grid item lg={3} sm={6} xl={3} xs={12}>
              <CheckErrors
                sx={{
                  backgroundColor: '#FFF8DC',
                  color: 'white',
                  padding: '10px',
                  borderRadius: '5px',
                  height: '100%'
                }}
                title="ERRORS"
                errorCount={null}
                errorLinks={null}
              />
            </Grid>

            <Grid item lg={6} md={6} xl={3} xs={12}>
              <TrafficByDevice userStats={userStats} sx={{ height: '70%' }} />
            </Grid>

            <Grid item lg={6} md={6} xl={3} xs={12}>
              <CountUsers userStats={userStats} />
            </Grid>

            <Grid item lg={12} md={12} xl={6} xs={12}>
              <GroupsLast />
            </Grid>

            <Grid item lg={12} md={12} xl={12} xs={12}>
              <GroupsExpired sx={{}} />
            </Grid>

            <Grid item lg={12} md={12} xl={12} xs={12}>
              <Sales />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default Dashboard;
