import { Alert, AlertTitle, Box } from '@material-ui/core';
import PropTypes from 'prop-types';

const GroupAlert = ({ status, message, onClose, title }) => {
  if (!status) {
    return <></>;
  }

  return (
    <Box sx={{ pt: 2 }}>
      <Alert onClose={onClose} severity={status}>
        {title && <AlertTitle>{title}</AlertTitle>}
        {message}
      </Alert>
    </Box>
  );
};

GroupAlert.propTypes = {
  status: PropTypes.string,
  message: PropTypes.string,
  onClose: PropTypes.func,
  title: PropTypes.string
};

GroupAlert.defaultProps = {
  status: '',
  message: '',
  onClose: () => {},
  title: ''
};

export default GroupAlert;

