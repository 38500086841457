/* eslint-disable */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { getTransport } from './enviroment';
import UserData from './components/account/UserData';

const generateHook = (callback) => () => {
  const navigate = useNavigate();
  const { logOut } = UserData();

  return callback((response) => {
    if (response.status === 401 || response?.data?.relogin) {
      logOut();
      navigate('/login', { replace: true });
    }
    return response.data;
  });
};

export const useGet = generateHook(
  (middleware) => (path, config) =>
    getTransport()
      .get(`/${path}`, config)
      .then((response) => middleware(response))
      .catch((err) => err)
);

export const usePost = generateHook(
  (middleware) => (path, payload, config) =>
    getTransport()
      .post(`/${path}`, payload, config)
      .then((response) => middleware(response))
      .catch((err) => err)
);

export const usePut = generateHook(
  (middleware) =>
    (path, payload = {}) =>
      getTransport()
        .put(`/${path}`, payload)
        .then((response) => middleware(response))
        .catch((err) => err)
);

export const useHttpDelete = generateHook(
  (middleware) => (path, config) =>
    getTransport()
      .delete(`/${path}`, config)
      .then((response) => middleware(response))
      .catch((err) => err)
);
