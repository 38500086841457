import {
  Box,
  Breadcrumbs,
  Button,
  Container,
  Tab,
  Tabs,
  Typography
} from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import GroupListResults from '../components/group/GroupListResults';
import GroupListResultsExpiresExpirationDate from '../components/group/GroupListResultsExpiresExpirationDate';

const GroupList = () => {
  const [tab, setTab] = useState(0);
  const navigate = useNavigate();

  const handleTab = (_, value) => setTab(value);

  return (
    <>
      <Helmet>
        <title>Groups | Material Kit</title>
      </Helmet>
      <Box sx={{ backgroundColor: 'background.default', minHeight: '100%' }}>
        <Container maxWidth={false}>
          <Box sx={{ py: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', pb: 1 }}>
              <Box style={{ marginTop: 3, marginRight: 10 }}>
                <Button
                  startIcon={<ChevronLeft />}
                  onClick={() => navigate(-1)}
                >
                  Back
                </Button>
              </Box>
              <Typography color="textPrimary" variant="h4" component="h1">
                Groups
              </Typography>
              <Box sx={{ display: 'flex', marginLeft: 'auto' }}>
                <RouterLink to="/app/group/sittings">
                  <Button color="success" variant="contained" sx={{ mr: 1 }}>
                    Settings group
                  </Button>
                </RouterLink>
                <RouterLink to="/app/group/add">
                  <Button color="primary" variant="contained">
                    Add group
                  </Button>
                </RouterLink>
              </Box>
            </Box>
            <Breadcrumbs aria-label="breadcrumb" style={{ fontSize: 12 }}>
              <p>Groups</p>
            </Breadcrumbs>
          </Box>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
              value={tab}
              onChange={handleTab}
              aria-label="basic tabs example"
            >
              <Tab
                label="All"
                id="simple-tab-0"
                {...{ 'aria-controls': 'simple-tabpanel-0' }}
              />
              <Tab
                label="Expiration date"
                id="simple-tab-1"
                {...{ 'aria-controls': 'simple-tabpanel-1' }}
              />
            </Tabs>
          </Box>
          <Box
            hidden={tab !== 0}
            id="simple-tabpanel-0"
            aria-labelledby="simple-tab-0"
          >
            {tab === 0 && <GroupListResults />}
          </Box>
          <Box
            hidden={tab !== 1}
            id="simple-tabpanel-1"
            aria-labelledby="simple-tab-1"
          >
            {tab === 1 && <GroupListResultsExpiresExpirationDate />}
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default GroupList;

